/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders a HTML string. Use with caution!
 */
const HTML = ({ tagName: Component, className, children }) => {
    if (!children) return null;
    return <Component className={className} dangerouslySetInnerHTML={{ __html: children }} />;
};

HTML.propTypes = {
    /** @ignore */
    className: PropTypes.string,

    /** Tag for wrapping component */
    tagName: PropTypes.string,

    /** Markdown string to convert */
    children: PropTypes.string,
};

HTML.defaultProps = {
    tagName: 'div',
    className: null,
    children: '',
};

export default HTML;
