import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Divider = ({ color, className, children }) => (
    <div className={cx('flex items-center px-8', className)} data-testid="divider">
        <span className="flex-grow h-px" css={{ background: color }} />
        {children && <div className="flex-no-shrink px-8">{children}</div>}
        <span className="flex-grow h-px" css={{ background: color }} />
    </div>
);

Divider.propTypes = {
    /** Color of the dividing line(s). Can be any valid color string. */
    color: PropTypes.string.isRequired,

    /** @ignore */
    className: PropTypes.string,

    /** What will be rendered in the center of the divider */
    children: PropTypes.node,
};

Divider.defaultProps = {
    className: '',
    children: null,
};

export default Divider;
