import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { DateTime } from 'luxon';
import isNumber from 'lodash/isNumber';

import CampaignPageContext from '../../context';

const ScheduleRow = ({ date, name, result, amountRaised }) => {
    const { theme } = useContext(CampaignPageContext);

    return (
        <tr className="text-sm md:text-base font-md" css={{ color: theme.colors.text }}>
            <td className="py-4 border-t" css={{ borderColor: theme.colors.border }}>
                {DateTime.fromISO(date).toFormat('M/d')}
            </td>
            <td
                className="py-4 border-t truncate max-w-px"
                css={{ borderColor: theme.colors.border }}
            >
                {name}
            </td>
            <td className="py-4 border-t text-center" css={{ borderColor: theme.colors.border }}>
                {isNumber(result) ? numeral(result).format('0,0[.]00') : '-'}
            </td>
            <td className="py-4 border-t text-right" css={{ borderColor: theme.colors.border }}>
                {amountRaised ? numeral(amountRaised).format('$0,0') : '-'}
            </td>
        </tr>
    );
};

ScheduleRow.propTypes = {
    date: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    result: PropTypes.number,
    amountRaised: PropTypes.number,
};

ScheduleRow.defaultProps = {
    result: null,
    amountRaised: null,
};

export default ScheduleRow;
