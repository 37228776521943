import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import get from 'lodash/get';
import { css } from '@emotion/core';

import { fullName } from 'utils';
import Avatar from 'common/avatar';
import Link from 'common/link';

class CampaignHeroProceeds extends Component {
    static propTypes = {
        theme: PropTypes.shape({
            colors: PropTypes.shape({
                link: PropTypes.string.isRequired,
                linkHover: PropTypes.string.isRequired,
            }).isRequired,
            overrides: PropTypes.shape({
                proceedStatement: PropTypes.object.isRequired,
            }).isRequired,
        }).isRequired,
        name: PropTypes.string,
        url: PropTypes.string,
        owner: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            avatar: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
        color: PropTypes.string,
        shadow: PropTypes.bool,
    };

    static defaultProps = {
        name: null,
        url: null,
        owner: null,
        color: config('/tailwind/colors/white'),
        shadow: false,
    };

    renderLeadIn() {
        const { owner, name } = this.props;
        if (!owner) return 'Proceeds benefit';

        const avatar = get(owner, 'avatar.id');

        return (
            <>
                {avatar && (
                    <Avatar
                        imageId={avatar}
                        firstName={owner.firstName}
                        lastName={owner.lastName}
                        className="w-16 h-16 rounded-full mr-6 hidden md:inline-block align-middle"
                    />
                )}
                Proceeds are being collected by {fullName(owner.firstName, owner.lastName)}
                {name ? ' for ' : ''}
            </>
        );
    }

    renderRecipient() {
        const { name, url, theme } = this.props;
        if (!url) return name;

        return (
            <Link
                to={url}
                css={css`
                    color: ${theme.colors.link};
                    &:hover {
                        color: ${theme.colors.linkHover};
                    }
                `}
                external
            >
                {name}
            </Link>
        );
    }

    render() {
        const { theme, color, shadow } = this.props;
        return (
            <p
                className="text-16 lg:text-18 xxl:text-21 font-medium mt-8"
                css={{
                    color,
                    textShadow: shadow ? `0 0 1px ${config('/tailwind/colors/black60')}` : 'none',
                    ...theme.overrides.proceedStatement,
                }}
            >
                {this.renderLeadIn()} {this.renderRecipient()}
            </p>
        );
    }
}

export default CampaignHeroProceeds;
