import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

import Video from 'common/video';

import CampaignPageContext from '../context';

const GET_VIDEO = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            video {
                url
            }
        }
    }
`;

const CampaignVideo = () => {
    const { id } = useContext(CampaignPageContext);
    return (
        <Query query={GET_VIDEO} variables={{ id }}>
            {({ error, loading, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { campaign } = data;
                if (!campaign.video) return null;

                return <Video url={campaign.video.url} className="mb-12" />;
            }}
        </Query>
    );
};

export default CampaignVideo;
