import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import Icon from 'common/icon';
import Modal from 'common/modal';

import CampaignContactForm from './contact-form';

class CampaignContact extends Component {
    static propTypes = {
        authEmail: PropTypes.string,
        contactCampaign: PropTypes.func.isRequired,
        reportCampaign: PropTypes.func.isRequired,
        showFlash: PropTypes.func.isRequired,
        isDraft: PropTypes.bool.isRequired,
        theme: PropTypes.shape([]),
    };

    static defaultProps = {
        authEmail: '',
        theme: {},
    };

    state = {
        formType: '',
        contactModalOpen: false,
    };

    /**
     * Toggle the modal open and close.
     *
     * @fires
     *  this.setState on contactModalOpen
     */
    toggleContactModalOpen = (formType = '') =>
        this.setState(prev => ({
            contactModalOpen: !prev.contactModalOpen,
            formType,
        }));

    render() {
        const {
            authEmail,
            contactCampaign,
            reportCampaign,
            showFlash,
            theme,
            isDraft,
        } = this.props;

        const { formType, contactModalOpen } = this.state;

        return (
            <div
                className={cx(
                    'mt-14 flex justify-center text-xs font-medium lg:pt-14 lg:border-t',
                    { 'opacity-25 pointer-events-none': isDraft }
                )}
                css={{
                    ...theme.overrides.contact,
                    borderColor: theme.colors.border,
                }}
            >
                <div className="w-sm max-w-full mx-auto flex justify-between">
                    <button
                        type="button"
                        className="transition text-red hover:text-red-dark flex-no-shrink"
                        onClick={() => this.toggleContactModalOpen('Report')}
                    >
                        <Icon icon="flag" size="lg" className="mr-2" />
                        Report campaign
                    </button>
                    <button
                        type="button"
                        className="transition text-green hover:text-green-dark flex-no-shrink"
                        onClick={() => this.toggleContactModalOpen('Contact')}
                    >
                        <Icon icon="envelope" size="lg" className="mr-2" />
                        Contact owner
                    </button>
                </div>
                <Modal close={() => this.toggleContactModalOpen()} isOpen={contactModalOpen}>
                    <CampaignContactForm
                        authEmail={authEmail}
                        contactCampaign={data => contactCampaign(data)}
                        flashMessage={variables => showFlash(variables)}
                        formType={formType}
                        onAfterSubmit={() => this.toggleContactModalOpen()}
                        onCancel={() => this.toggleContactModalOpen()}
                        reportCampaign={data => reportCampaign(data)}
                    />
                </Modal>
            </div>
        );
    }
}

export default CampaignContact;
