import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import useKey from '@rooks/use-key';
import uniqueId from 'lodash/uniqueId';
import BrowserOnly from 'common/browser-only';
import useScrollLock from 'hooks/use-scroll-lock';
import { ModalProvider } from 'common/modal/context';
import Portal from '../portal';

const ContainerPose = posed.div();

const ModalPose = posed.div({
    enter: { opacity: 1, translateY: 0 },
    exit: { opacity: 0, translateY: -50 },
});

const OverlayPose = posed.div({
    enter: { opacity: 0.85 },
    exit: { opacity: 0 },
});

const ModalScrollLock = () => {
    useScrollLock();
    return null;
};

const Modal = ({ children, close, isOpen }) => {
    useKey(['Escape'], close);

    const id = useRef(uniqueId('modal_'));

    return (
        <Portal>
            <PoseGroup withParent={false} animateOnMount>
                {isOpen && (
                    <ContainerPose
                        key="modal"
                        id={id.current}
                        className="pin w-full h-full z-40 overflow-auto p-6 md:12 lg:p-24"
                        role="presentation"
                        data-test="close"
                        css={{ position: 'fixed !important' }}
                        onClick={close}
                    >
                        <ModalScrollLock />
                        <ModalProvider id={id.current}>
                            <div className="flex justify-center items-center min-h-full relative z-20">
                                <ModalPose
                                    className="bg-white rounded-lg shadow-lg relative z-20"
                                    css={{ width: 400 }}
                                    onClick={e => e.stopPropagation()}
                                    role="presentation"
                                >
                                    {children}
                                </ModalPose>
                            </div>
                        </ModalProvider>
                        <OverlayPose className="fixed pin z-0 bg-gray-darkest" />
                    </ContainerPose>
                )}
            </PoseGroup>
        </Portal>
    );
};

Modal.propTypes = {
    children: PropTypes.node,
    close: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
    children: null,
};

export default BrowserOnly(Modal);
