import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isNumber from 'lodash/isNumber';

import Avatar from 'common/avatar';
import Icon from 'common/icon';
import Link from 'common/link';
import CampaignPageContext from '../../context';

const TrophyAvatar = ({ rank }) => (
    <div className="absolute w-full h-full pin flex justify-center items-center">
        <Icon
            icon="trophy"
            type="solid"
            size="1x"
            className={cx('text-lg', {
                'text-gold': rank === 1,
                'text-silver': rank === 2,
                'text-bronze': rank === 3,
            })}
        />
    </div>
);

TrophyAvatar.propTypes = {
    rank: PropTypes.number.isRequired,
};

const FundraiserItemTop = ({ url, bordered, avatar, firstName, lastName, rank, statValue }) => {
    const { theme } = useContext(CampaignPageContext);
    const isTopThree = isNumber(rank) && rank < 4;
    return (
        <Link
            to={url}
            className={cx('p-7 flex items-center border-t', {
                'border-t': bordered,
            })}
            css={{
                borderColor: theme.colors.panelSectionBorder,
                color: theme.colors.panelText,
            }}
        >
            <div
                className="flex-no-shrink w-12 text-right font-bold text-center"
                css={{ color: theme.colors.panelTextMuted }}
            >
                {rank || '-'}
            </div>
            <div className="flex-no-shrink px-6">
                <div className="relative">
                    <div className="w-12 h-12 bg-gray-90 rounded-full overflow-hidden">
                        <Avatar
                            imageId={avatar}
                            firstName={firstName}
                            lastName={lastName}
                            className={cx('block', {
                                'opacity-60': isTopThree,
                            })}
                        />
                    </div>
                    {isTopThree && <TrophyAvatar rank={rank} />}
                </div>
            </div>
            <div className="flex-grow font-medium truncate">
                {firstName} {lastName}
            </div>
            <div className="flex-no-shrink font-medium pl-6">{statValue}</div>
        </Link>
    );
};

FundraiserItemTop.propTypes = {
    url: PropTypes.string.isRequired,
    bordered: PropTypes.bool.isRequired,
    statValue: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    rank: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
};

FundraiserItemTop.defaultProps = {
    rank: null,
    firstName: '',
    lastName: '',
};

export default FundraiserItemTop;
