import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Avatar from 'common/avatar';
import Button from 'common/button';
import Link from 'common/link';
import Icon from 'common/icon';

import EditableFundraiserMessage from './editable-message';

const CampaignFundraiser = ({
    formattedAmountRaised,
    fullUrl,
    fundraiser,
    fundraiserCount,
    isRanked,
    rank,
    referralCount,
    showFlash,
    theme,
    url,
    updateFundraiser,
}) => (
    <div className="lg:-mt-48">
        <div
            className="p-12 rounded shadow-lg mb-12 text-base leading-loose"
            css={{
                background: theme.colors.panelBackground,
                color: theme.colors.panelText,
            }}
        >
            <div className="md:flex -ml-8 -mt-8">
                <div className="pl-8 pt-8 flex-no-shrink">
                    <Avatar
                        firstName={fundraiser.user.firstName}
                        lastName={fundraiser.user.lastName}
                        imageId={fundraiser.user.avatar.id}
                        className="w-24 rounded-full block"
                    />
                </div>
                <div className="pl-8 pt-8 flex-grow">
                    <p className="font-bold mb-4">Your personal message:</p>

                    <EditableFundraiserMessage
                        message={fundraiser.message}
                        onUpdate={updateFundraiser}
                        showFlash={showFlash}
                        theme={theme}
                    />

                    <div className="mt-8 font-medium">
                        <div
                            className="flex justify-between align-middle border-b py-3"
                            css={{
                                borderColor: theme.colors.panelSectionBorder,
                            }}
                        >
                            <p className="truncate">Current ranking</p>
                            {isRanked ? (
                                <p>
                                    {rank} of {fundraiserCount}
                                </p>
                            ) : (
                                <p css={{ color: theme.colors.panelTextMuted }}>Unranked</p>
                            )}
                        </div>
                        <div
                            className="flex justify-between align-middle border-b py-3"
                            css={{
                                borderColor: theme.colors.panelSectionBorder,
                            }}
                        >
                            <p className="truncate">Amount raised</p>
                            <p>{formattedAmountRaised}</p>
                        </div>
                        <div className="flex justify-between align-middle py-3">
                            <p className="truncate">Pledgers supporting you</p>
                            <p>{referralCount}</p>
                        </div>
                    </div>

                    <div className="mt-4">
                        <Button
                            component={Link}
                            to={`${url}/supporters`}
                            className="font-medium mr-8 mt-4"
                            size="sm"
                            css={{
                                background: theme.colors.buttonBackground,
                                color: theme.colors.buttonText,
                                '&:hover': {
                                    background: theme.colors.buttonHoverBackground,
                                    color: theme.colors.buttonHoverText,
                                },
                            }}
                        >
                            View / add more supporters
                        </Button>
                        <CopyToClipboard
                            text={fullUrl}
                            onCopy={() =>
                                showFlash({
                                    type: 'info',
                                    iconName: 'link',
                                    iconStyle: 'regular',
                                    title: 'Copied!',
                                    message: 'Your url has been copied to your clipboard.',
                                    duration: 3000,
                                })
                            }
                        >
                            <Button
                                type="button"
                                size="sm"
                                className="mt-4"
                                css={{
                                    color: theme.colors.buttonBackground,
                                    '&:hover': {
                                        color: theme.colors.buttonHoverBackground,
                                    },
                                }}
                            >
                                <Icon icon="link" size="1x" className="mr-4" />
                                Copy your url
                            </Button>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

CampaignFundraiser.propTypes = {
    formattedAmountRaised: PropTypes.string.isRequired,
    fullUrl: PropTypes.string.isRequired,
    fundraiser: PropTypes.shape({
        message: PropTypes.string,
        user: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            avatar: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
    }).isRequired,
    fundraiserCount: PropTypes.number.isRequired,
    isRanked: PropTypes.bool.isRequired,
    rank: PropTypes.number.isRequired,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            panelBackground: PropTypes.string,
            panelText: PropTypes.string,
            panelSectionBorder: PropTypes.string,
            panelTextMuted: PropTypes.string,
            buttonBackground: PropTypes.string,
            buttonText: PropTypes.string,
            buttonHoverBackground: PropTypes.string,
            buttonHoverText: PropTypes.string,
        }),
    }).isRequired,
    url: PropTypes.string.isRequired,
    referralCount: PropTypes.number.isRequired,
    showFlash: PropTypes.func.isRequired,
    updateFundraiser: PropTypes.func.isRequired,
};

export default CampaignFundraiser;
