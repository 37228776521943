import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { Image } from 'cloudinary-react';

import Button from 'common/button';

const FundraiseIntro = ({ onContinue }) => (
    <>
        <div className="w-64 h-64 block my-4 mx-auto">
            <Image publicId="fundraise-step3" />
        </div>
        <h1 className="text-lg font-bold leading-tight my-4 text-gray-dark">
            Join the Fundraising Team
        </h1>
        <p className="text-base leading-normal my-4">
            You believe in this campaign. Write a brief message about why this campaign is awesome
            and share it with your family and friends.
        </p>
        <div className="mt-8">
            <Button
                type="button"
                color="green"
                size="md"
                className="text-14 font-medium block w-full"
                onClick={onContinue}
            >
                Edit your personal message
            </Button>
        </div>
    </>
);

FundraiseIntro.propTypes = {
    onContinue: PropTypes.func,
};

FundraiseIntro.defaultProps = {
    onContinue: noop,
};

export default FundraiseIntro;
