import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

import CampaignMatch from './campaign-match';

import CampaignPageContext from '../context';

const GET_MATCH = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            sponsor {
                name
                logoDark {
                    id
                }
            }
            match {
                id
                messaging {
                    title
                    description
                }
            }
        }
    }
`;

const CampaignMatchContainer = () => {
    const { id, slug } = useContext(CampaignPageContext);

    return (
        <Query query={GET_MATCH} variables={{ id }}>
            {({ error, loading, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { sponsor, match } = data.campaign;

                return <CampaignMatch match={match} slug={slug} sponsor={sponsor} />;
            }}
        </Query>
    );
};

export default CampaignMatchContainer;
