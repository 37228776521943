import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';
import numeral from 'numeral';
import posed, { PoseGroup } from 'react-pose';

import { formatCurrency } from 'utils';
import CampaignPageContext from '../../context';

const ChartPose = posed.div({
    enter: { opacity: 1 },
    exit: { opacity: 0 },
});

const CampaignProgressDial = ({
    totalRaised,
    amountEarned,
    amountPledged,
    isEstimate,
    target,
    showPerformanceOverview,
    performanceEstimate,
    performanceValue,
    performanceMetric,
}) => {
    const { theme, isAnimationComplete } = useContext(CampaignPageContext);

    let amountRemaining = target - (amountEarned + amountPledged);
    if (amountRemaining < 0) amountRemaining = 0;

    const formattedPerformanceEstimate = numeral(performanceEstimate).format('0,0');
    const performanceMetricPhrasing =
        performanceEstimate === 1
            ? `${performanceMetric.singular} worth`
            : `${performanceMetric.plural}, each worth`;

    const chartData = [
        {
            id: 'earned',
            label: 'Earned',
            value: amountEarned,
            color: theme.colors.progressEarned,
        },
        {
            id: 'estimated',
            label: 'Estimated',
            value: amountPledged,
            color: theme.colors.progressEstimated,
        },
        {
            id: 'remaining',
            label: 'Remaining',
            value: amountRemaining,
            color: theme.colors.progressRemaining,
        },
    ];

    const chartColors = chartData.map(x => x.color);

    return (
        <div>
            <div className="aspect-ratio-square relative">
                <PoseGroup withParent={false} animateOnMount>
                    {isAnimationComplete && (
                        <ChartPose key="campaign-dial">
                            <div
                                className="absolute pin-x pin-y w-full h-full z-10 flex flex-col justify-center items-center text-center"
                                css={{ padding: '25%', ...theme.overrides.amountRaised }}
                            >
                                <p
                                    className="font-number font-medium"
                                    css={{ fontSize: '5rem', color: theme.colors.panelText }}
                                >
                                    <sup css={{ fontSize: '65%' }}>$</sup>
                                    {formatCurrency(totalRaised, {
                                        includeSymbol: false,
                                        cents: 'never',
                                    })}
                                </p>
                                <p
                                    className="text-2xs font-bold uppercase tracking-wide"
                                    css={{ color: theme.colors.panelTextMuted }}
                                >
                                    {isEstimate ? 'Est. total raised of ' : 'Earned of '}
                                    {formatCurrency(target)} goal
                                </p>
                            </div>
                            <div className="absolute pin-x pin-y w-full h-full z-20">
                                <ResponsivePie
                                    data={chartData}
                                    colors={chartColors}
                                    innerRadius={0.8}
                                    sortByValue={false}
                                    enableRadialLabels={false}
                                    enableSlicesLabels={false}
                                    tooltip={({ label, value, color }) => (
                                        <div className="text-center font-bold text-gray-darker px-4 py-2">
                                            <p
                                                className="text-2xs uppercase tracking-wide"
                                                css={{ color }}
                                            >
                                                {label}
                                            </p>
                                            <p className="text-base">{formatCurrency(value)}</p>
                                        </div>
                                    )}
                                />
                            </div>
                        </ChartPose>
                    )}
                </PoseGroup>
            </div>

            {showPerformanceOverview && (
                <p
                    className="text-center leading-normal font-medium text-base mt-12"
                    css={{ color: theme.colors.panelText }}
                >
                    Based on {formattedPerformanceEstimate} {isEstimate && 'estimated'}{' '}
                    {performanceMetricPhrasing} {formatCurrency(performanceValue)}
                </p>
            )}
        </div>
    );
};

CampaignProgressDial.propTypes = {
    totalRaised: PropTypes.number.isRequired,
    amountEarned: PropTypes.number.isRequired,
    amountPledged: PropTypes.number.isRequired,
    isEstimate: PropTypes.bool.isRequired,
    target: PropTypes.number.isRequired,
    showPerformanceOverview: PropTypes.bool.isRequired,
    performanceEstimate: PropTypes.number,
    performanceValue: PropTypes.number,
    performanceMetric: PropTypes.shape({
        singular: PropTypes.string,
        plural: PropTypes.string,
    }),
};

CampaignProgressDial.defaultProps = {
    performanceEstimate: 0,
    performanceValue: 0,
    performanceMetric: {
        singular: 'result',
        plural: 'results',
    },
};
export default CampaignProgressDial;
