import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import useBreakpoint from 'hooks/use-breakpoint';
import SkewText from 'common/skew-text';
import CampaignPageContext from '../../context';

const CampaignHeroTitle = ({ children }) => {
    const { theme } = useContext(CampaignPageContext);
    const { md, xxl } = useBreakpoint();

    let fontSize = '5rem';
    if (md) fontSize = '6rem';
    if (xxl) fontSize = '7rem';

    return (
        <SkewText
            component="h1"
            backgroundColor={theme.colors.titleBackground}
            textColor={theme.colors.titleText}
            className="font-display uppercase font-bold leading-none lg:w-3/4"
            css={{ fontSize, ...theme.overrides.title }}
        >
            {children}
        </SkewText>
    );
};

CampaignHeroTitle.propTypes = {
    children: PropTypes.node,
};

CampaignHeroTitle.defaultProps = {
    children: null,
};

export default CampaignHeroTitle;
