import React from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import get from 'lodash/get';

import CampaignProgressDial from './progress-dial';
import CampaignPulse from './pulse';
import DetailedStats from './detailed';
import SidebarSponsor from '../sponsor/sidebar';

const StatPose = posed.div({
    initial: {
        opacity: 0,
        rotateY: 180,
        transition: { type: 'tween', duration: 600 },
    },

    enter: {
        opacity: 1,
        rotateY: 0,
        delay: 100,
        transition: { type: 'tween', duration: 600 },
    },

    exit: {
        opacity: 0,
        rotateY: -180,
        transition: { type: 'tween', duration: 600 },
    },
});

const CampaignStats = ({
    amountRaised,
    amountEarned,
    campaign,
    isShowingDetails,
    shouldDisplaySponsor,
    theme,
    toggleDetails,
}) => (
    <div
        className="rounded-lg lg:rounded-t-none shadow-lg p-12"
        css={{
            background: theme.colors.panelBackground,
            ...theme.overrides.stats,
        }}
    >
        <div className="relative" css={{ perspective: 1000 }}>
            <PoseGroup animateOnMount={false} preEnterPose="initial" withParent={false}>
                {isShowingDetails ? (
                    <StatPose
                        key="details"
                        css={{
                            transformStyle: 'preserve-3d',
                            backfaceVisibility: 'hidden',
                        }}
                    >
                        <DetailedStats
                            performanceMetric={
                                campaign.isDonationOnly
                                    ? undefined
                                    : campaign.metricName || undefined
                            }
                            pastPerformance={get(campaign, 'stats.performanceActualTotal', 0)}
                            pastAmountPledged={get(campaign, 'stats.pledgeValuePast', 0)}
                            pastAmountRaised={get(campaign, 'stats.amountRaisedEstimatePast', 0)}
                            futurePerformance={
                                campaign.isComplete
                                    ? null
                                    : get(campaign, 'stats.performanceRemaining', 0)
                            }
                            futureAmountPledged={
                                campaign.isComplete
                                    ? null
                                    : get(campaign, 'stats.amountRaisedEstimateFuture', 0) /
                                      get(campaign, 'stats.performanceRemaining', 0)
                            }
                            futureAmountRaised={
                                campaign.isComplete
                                    ? null
                                    : get(campaign, 'stats.amountRaisedEstimateFuture', 0)
                            }
                        />
                    </StatPose>
                ) : (
                    <StatPose
                        key="summary"
                        css={{
                            transformStyle: 'preserve-3d',
                            backfaceVisibility: 'hidden',
                        }}
                    >
                        <CampaignProgressDial
                            totalRaised={amountRaised}
                            amountEarned={amountEarned}
                            amountPledged={get(campaign, 'stats.amountRaisedEstimateFuture', 0)}
                            isEstimate={!campaign.isDonationOnly && !campaign.isComplete}
                            target={campaign.goal || 1}
                            showPerformanceOverview={!campaign.isDonationOnly}
                            performanceEstimate={get(
                                campaign,
                                'stats.performanceEstimatedTotal',
                                0
                            )}
                            performanceMetric={
                                campaign.isDonationOnly
                                    ? undefined
                                    : campaign.metricName || undefined
                            }
                            performanceValue={get(campaign, 'stats.pledgeValue', 0)}
                        />
                    </StatPose>
                )}
            </PoseGroup>
        </div>

        {!campaign.isDonationOnly && (
            <div className="mt-12 text-center">
                <button
                    type="button"
                    className="text-xs uppercase font-bold tracking-wide transition"
                    onClick={toggleDetails}
                    css={{
                        color: theme.colors.link,
                        '&:hover': {
                            color: theme.colors.linkHover,
                        },
                    }}
                >
                    {isShowingDetails ? 'Hide Details' : 'Show Details'}
                </button>
            </div>
        )}

        <CampaignPulse
            isComplete={campaign.isComplete}
            endDate={campaign.completionAt}
            contributorCount={get(campaign, 'stats.contributorCount', 0)}
        />

        {shouldDisplaySponsor && <SidebarSponsor />}
    </div>
);

CampaignStats.propTypes = {
    amountRaised: PropTypes.number.isRequired,
    amountEarned: PropTypes.number.isRequired,
    campaign: PropTypes.shape({
        isComplete: PropTypes.bool,
        goal: PropTypes.number,
        metricName: PropTypes.shape({
            singular: PropTypes.string,
            plural: PropTypes.string,
        }),
        isDonationOnly: PropTypes.bool,
        completionAt: PropTypes.string,
    }).isRequired,
    isShowingDetails: PropTypes.bool.isRequired,
    shouldDisplaySponsor: PropTypes.bool.isRequired,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            panelBackground: PropTypes.string,
            linkHover: PropTypes.string,
            link: PropTypes.string,
        }),
        overrides: PropTypes.object,
    }).isRequired,
    toggleDetails: PropTypes.func.isRequired,
};

export default CampaignStats;
