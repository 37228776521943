import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import GoogleContext from 'data/services/google';
import Button from 'common/button';
import Icon from 'common/icon';

const FundraiserContacts = ({ onEditMessage, onSkip, onEnterContacts, onImportContacts }) => {
    const { ensureScope } = useContext(GoogleContext);

    return (
        <>
            <h1 className="text-lg font-bold leading-tight my-4 text-gray-dark">
                Now the most important step!
            </h1>
            <p className="text-base leading-normal my-4">
                Tell your friends and family about your campaign! People you reach out to now are 3x
                more likely to support your campaign.
            </p>
            <div className="text-center text-12 font-medium mt-4">
                <button type="button" onClick={onEditMessage} className="link-green">
                    View/edit your message
                </button>
            </div>
            <div className="mt-12">
                <Button
                    type="button"
                    color="google"
                    size="md"
                    className="text-14 font-medium flex items-center w-full"
                    onClick={async () => {
                        await ensureScope('https://www.googleapis.com/auth/contacts.readonly');
                        onImportContacts();
                    }}
                >
                    <Icon icon="google" type="brand" size="lg" className="mr-5" />
                    Import contacts from Google
                </Button>
                <Button
                    type="button"
                    color="blue"
                    size="md"
                    className="text-14 font-medium w-full mt-2"
                    onClick={onEnterContacts}
                >
                    Manually enter contacts
                </Button>
                <Button
                    type="button"
                    size="md"
                    className="text-14 link-gray font-medium w-full mt-2"
                    onClick={onSkip}
                >
                    Skip this step
                </Button>
            </div>
        </>
    );
};

FundraiserContacts.propTypes = {
    onEditMessage: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
    onEnterContacts: PropTypes.func.isRequired,
    onImportContacts: PropTypes.func.isRequired,
};

export default FundraiserContacts;
