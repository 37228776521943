import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, FieldArray, getIn } from 'formik';
import * as yup from 'yup';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';
import without from 'lodash/without';
import posed, { PoseGroup } from 'react-pose';
import cx from 'classnames';

import Button from 'common/button';
import Icon from 'common/icon';

const FieldsPose = posed.div({
    enter: { opacity: 1, translateY: 0, duration: 1500 },
    exit: { opacity: 0, translateY: -10 },
});

const checkContentFieldCounts = debounce(
    ({ referrals }, setFieldValue) => {
        const removeItem = referrals.filter(({ name, email }) => name === '' && email === '');

        if (removeItem.length === 0) {
            setFieldValue('referrals', [
                ...referrals,
                { name: '', email: '', uuid: uniqueId('referrals') },
            ]);
        }

        if (removeItem.length > 1 && referrals.length > 2) {
            setFieldValue('referrals', without(referrals, removeItem[0]));
        }
    },
    50,
    { trailing: true }
);

const FundraiseManualRefer = ({ onSubmit, onCancel }) => (
    <>
        <h1 className="text-lg font-bold leading-tight my-4 mb-10 text-gray-dark">
            Enter Contacts
        </h1>
        <Formik
            initialValues={{
                referrals: [
                    { name: '', email: '', uuid: uniqueId('referrals') },
                    { name: '', email: '', uuid: uniqueId('referrals') },
                ],
            }}
            validationSchema={yup.object().shape({
                referrals: yup
                    .array()
                    .of(
                        yup.object().shape({
                            name: yup.string().required('This field is required'),
                            email: yup
                                .string()
                                .email('Invalid email')
                                .required('This field is required'),
                        })
                    )
                    .compact(({ name, email }) => !name && !email),
            })}
            onSubmit={({ referrals }, ...args) =>
                onSubmit(
                    referrals
                        .filter(({ name, email }) => name && email)
                        .map(({ name, email }) => {
                            const splitNames = name.trim().split(' ');
                            const firstName = splitNames.shift();
                            const lastName = splitNames.join(' ');
                            return { email, firstName, lastName };
                        }),
                    ...args
                )
            }
        >
            {({ errors, isSubmitting, setFieldValue, touched, values }) => {
                checkContentFieldCounts(values, setFieldValue);

                return (
                    <Form>
                        <FieldArray name="referrals">
                            <>
                                <PoseGroup withParent={false} animateOnMount>
                                    {values.referrals.map((referral, index) => {
                                        const nameId = `referrals[${index}].name`;
                                        const emailId = `referrals[${index}].email`;
                                        const nameIsInvalid =
                                            getIn(touched, nameId) && getIn(errors, nameId);
                                        const emailIsInvalid =
                                            getIn(touched, emailId) && getIn(errors, emailId);

                                        return (
                                            <FieldsPose key={referral.uuid} className="mt-4">
                                                <div className="p-2 border border-gray-lighter flex text-left bg-white relative">
                                                    <label
                                                        htmlFor={nameId}
                                                        className={cx(
                                                            'font-medium w-1/4 text-xs ml-2 self-center',
                                                            { 'text-red': nameIsInvalid }
                                                        )}
                                                    >
                                                        Full Name:
                                                    </label>
                                                    <Field
                                                        type="text"
                                                        name={nameId}
                                                        placeholder="John Smith"
                                                        className={cx(
                                                            'input-default w-3/4 bg-white border-0 h-4 pr-0',
                                                            { 'is-invalid': nameIsInvalid }
                                                        )}
                                                    />
                                                </div>
                                                <div className="p-2 border border-t-0 border-gray-lighter flex text-left bg-white relative">
                                                    <label
                                                        htmlFor={emailId}
                                                        className={cx(
                                                            'w-1/4 font-medium text-xs ml-2 self-center',
                                                            { 'text-red': emailIsInvalid }
                                                        )}
                                                    >
                                                        Email:
                                                    </label>
                                                    <Field
                                                        type="email"
                                                        name={emailId}
                                                        placeholder="john.smith@example.com"
                                                        className={cx(
                                                            'input-default w-3/4 bg-white border-0 h-4',
                                                            { 'is-invalid': emailIsInvalid }
                                                        )}
                                                    />
                                                </div>
                                            </FieldsPose>
                                        );
                                    })}
                                </PoseGroup>
                            </>
                        </FieldArray>

                        <div className="mt-8">
                            <Button
                                type="submit"
                                color="green"
                                size="md"
                                className={cx('text-14 font-medium block w-full', {
                                    'pointer-events-none opacity-60': isSubmitting,
                                })}
                            >
                                {isSubmitting ? (
                                    <Icon icon="spinner" size="lg" className="leading-none" spin />
                                ) : (
                                    'Share my campaign'
                                )}
                            </Button>
                            <Button
                                type="button"
                                size="md"
                                className="text-14 font-medium block w-full link-gray"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    </>
);

FundraiseManualRefer.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default FundraiseManualRefer;
