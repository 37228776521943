import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

import config from 'config';
import CampaignPledgeButton from './pledge-button';
import CampaignPageContext from '../context';

const GET_BUTTON = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            isDonationOnly
            isComplete
            slug
        }
    }
`;

const CampaignPledgeButtonContainer = () => {
    const { id, isDraft, theme } = useContext(CampaignPageContext);

    return (
        <Query query={GET_BUTTON} variables={{ id }}>
            {({ loading, error, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { campaign } = data;
                const url = `/${campaign.slug}/contribute`;

                let label = 'Pledge Now';
                if (campaign.isDonationOnly) label = 'Donate Now';
                if (campaign.isComplete) label = 'Completed';

                const buttonStyles = campaign.isComplete
                    ? {
                          background: config('/tailwind/colors/grayBase'),
                          color: config('/tailwind/colors/white'),
                      }
                    : {
                          background: theme.colors.buttonBackground,
                          color: theme.colors.buttonText,
                          '&:hover': {
                              background: theme.colors.buttonHoverBackground,
                              color: theme.colors.buttonHoverText,
                          },
                      };

                return (
                    <CampaignPledgeButton
                        buttonStyles={buttonStyles}
                        campaign={campaign}
                        isDraft={isDraft}
                        label={label}
                        theme={theme}
                        url={url}
                    />
                );
            }}
        </Query>
    );
};

export default CampaignPledgeButtonContainer;
