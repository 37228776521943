import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';

import GlobalContext from 'site/context';
import Button from 'common/button';
import Icon from 'common/icon';

const FundraiseUserDetails = ({ onChangeUser, onSubmit }) => {
    const { user } = useContext(GlobalContext);

    return (
        <>
            <h1 className="text-lg font-bold leading-tight my-4 text-gray-dark">
                We just need a little information:
            </h1>
            <Formik
                initialValues={{
                    firstName: user.firstName || '',
                    lastName: user.lastName || '',
                    email: user.email || '',
                    username: user.username || '',
                }}
                validationSchema={yup.object().shape({
                    firstName: yup.string().required('First name is required'),
                    lastName: yup.string().required('Last name is required'),
                    email: yup
                        .string()
                        .email('Invalid field')
                        .required('Email is required'),
                    username: yup
                        .string()
                        .required('Username is required')
                        .matches(/^[a-zA-Z0-9\-_]+$/, {
                            message: 'Letters and numbers only with no spaces',
                        }),
                })}
                onSubmit={onSubmit}
            >
                {({ isSubmitting, touched, errors }) => (
                    <Form className="-mt-2">
                        <div className="mt-2">
                            <Field
                                name="firstName"
                                placeholder="First name"
                                className={cx('input-default w-full', {
                                    'is-invalid': touched.firstName && errors.firstName,
                                })}
                            />
                            <ErrorMessage
                                component="p"
                                className="text-red mt-2 text-12"
                                name="firstName"
                            />
                        </div>
                        <div className="mt-2">
                            <Field
                                name="lastName"
                                placeholder="Last name"
                                className={cx('input-default w-full', {
                                    'is-invalid': touched.lastName && errors.lastName,
                                })}
                            />
                            <ErrorMessage
                                component="p"
                                className="text-red mt-2 text-12"
                                name="lastName"
                            />
                        </div>
                        <div className="mt-2">
                            <Field
                                type="email"
                                name="email"
                                placeholder="Email"
                                className={cx('input-default w-full', {
                                    'is-invalid': touched.email && errors.email,
                                })}
                            />
                            <ErrorMessage
                                component="p"
                                className="text-red mt-2 text-12"
                                name="email"
                            />
                        </div>
                        <div className="mt-2">
                            <Field
                                type="username"
                                name="username"
                                placeholder="Username (Used for your URL)"
                                className={cx('input-default w-full', {
                                    'is-invalid': touched.username && errors.userName,
                                })}
                                disabled={!!user.username}
                            />
                            <ErrorMessage
                                component="p"
                                className="text-red mt-2 text-12"
                                name="username"
                            />
                        </div>

                        <div className="mt-8 text-12 font-medium text-gray-70">
                            <p>
                                <button type="button" className="link-green" onClick={onChangeUser}>
                                    Sign in to a different account.
                                </button>
                            </p>
                        </div>

                        <div className="mt-8">
                            <Button
                                type="submit"
                                color="green"
                                size="md"
                                className={cx('text-14 font-medium block w-full', {
                                    'pointer-events-none opacity-60': isSubmitting,
                                })}
                            >
                                {isSubmitting ? (
                                    <Icon icon="spinner" size="lg" className="leading-none" spin />
                                ) : (
                                    'Submit my information'
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

FundraiseUserDetails.propTypes = {
    onChangeUser: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default FundraiseUserDetails;
