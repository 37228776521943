import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import posed, { PoseGroup } from 'react-pose';

import Container from 'common/container';
import LargeScreen from 'common/large-screen';
import SmallScreen from 'common/small-screen';
import { CampaignPageProvider } from './context';
import Wrapper from './wrapper';
import SEO from './seo';
import Headline from './headline';
import Content from './content';
import Schedule from './schedule';
import Highlight from './highlight';
import Hero from './hero';
import Recipient from './recipient';
import Video from './video';
import Comments from './comments';
import PledgeButton from './pledge-button';
import Stats from './stats';
import Share from './share';
import Contact from './contact';
import ContentMask from './content-mask';
import Fundraisers from './fundraisers';
import Fundraiser from './fundraiser';
import ManagerActions from './manager-actions';
import FundraiserActions from './fundraiser-actions';
import ContributorActions from './contributor-actions';
import SponsorMatch from './match';
import StorySponsor from './sponsor/story';
import ContributionThanks from '../contribute/thanks';

const ColumnPose = posed.div({
    enter: {
        opacity: 1,
        transition: { duration: 250 },
        delay: ({ order }) => (order + 1) * 200,
    },
    exit: {
        opacity: 0,
        transition: { duration: 250 },
        delay: ({ order }) => (order + 1) * 200,
    },
});

const initialColumnStyles = { opacity: 0 };

const CampaignPage = ({
    campaign,
    roles,
    user,
    fundraiser,
    recentContribution,
    clearRecentContribution,
    setReferrer,
    isFundraisingOnLoad,
}) => {
    const hasMatch = !!campaign.matchId;
    const shouldDisplaySponsor = get(campaign, 'sponsor.displayInStory');
    const isManager = roles.includes('manager');
    const isDonor = roles.includes('donor');
    const isPledger = roles.includes('pledger');
    const isContributor = isDonor || isPledger;
    const isOwnFundraiserPage = fundraiser && user && fundraiser === user.username;
    const canFundraise = campaign.enableFundraisers;

    const [showThanksModal, setShowThanksModal] = useState(!!recentContribution);
    const [isAnimationComplete, setAnimationComplete] = useState(false);

    useEffect(() => setReferrer(fundraiser));

    return (
        <CampaignPageProvider
            defaultMessage={campaign.defaultFundraiserMessage}
            fundraiser={fundraiser}
            id={campaign.id}
            slug={campaign.slug}
            status={campaign.status}
            theme={campaign.theme}
            themeOverrides={campaign.themeOverrides}
            isFundraisingOnLoad={isFundraisingOnLoad}
            isAnimationComplete={isAnimationComplete}
        >
            <Wrapper className="relative">
                <SEO />
                {isManager && <ManagerActions />}
                <Hero />
                <Container className="max-w-xxxl lg:flex lg:flex-row-reverse relative z-30">
                    <PoseGroup withParent={false} animateOnMount>
                        <ColumnPose
                            key="stat-col"
                            className="w-full lg:w-2/5 xxl:w-1/3 mt-16 lg:-mt-24"
                            css={initialColumnStyles}
                            order={1}
                            onPoseComplete={() => setAnimationComplete(true)}
                        >
                            <div
                                className="mx-auto lg:sticky lg:mb-24"
                                css={{ maxWidth: 420, top: 80 }}
                            >
                                {isContributor ? <ContributorActions /> : <PledgeButton />}
                                <Stats />
                                <div>
                                    <Share />
                                    <LargeScreen>
                                        <Contact />
                                    </LargeScreen>
                                </div>
                            </div>
                        </ColumnPose>
                        <ColumnPose
                            key="content-col"
                            className="w-full lg:w-3/5 xxl:w-2/3 py-24 lg:pr-12 xl:pr-16"
                            css={initialColumnStyles}
                            order={2}
                        >
                            <div>
                                {fundraiser &&
                                    (isOwnFundraiserPage ? (
                                        <FundraiserActions fundraiser={fundraiser} />
                                    ) : (
                                        <Fundraiser fundraiser={fundraiser} />
                                    ))}
                                <Video />
                                <ContentMask height={600} closedText="Read Full Story">
                                    <Headline />
                                    <Content />
                                </ContentMask>
                                <Highlight />
                                <Schedule />
                                {hasMatch && <SponsorMatch />}
                                {canFundraise && <Fundraisers />}
                                <Recipient />
                                {shouldDisplaySponsor && <StorySponsor />}
                                <SmallScreen>
                                    <Contact />
                                </SmallScreen>
                                <Comments />
                            </div>
                        </ColumnPose>
                    </PoseGroup>
                </Container>
            </Wrapper>
            {!!recentContribution && (
                <ContributionThanks
                    {...recentContribution}
                    isOpen={showThanksModal}
                    onClose={() => {
                        setShowThanksModal(false);
                        clearRecentContribution();
                    }}
                />
            )}
        </CampaignPageProvider>
    );
};

CampaignPage.propTypes = {
    campaign: PropTypes.shape({
        enableFundraisers: PropTypes.bool,
        defaultFundraiserMessage: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        slug: PropTypes.string,
        status: PropTypes.oneOf(['draft', 'published']).isRequired,
        theme: PropTypes.oneOf(['light', 'dark']),
        themeOverrides: PropTypes.string,
        matchId: PropTypes.string,
        sponsor: PropTypes.shape({
            displayInStory: PropTypes.bool,
        }),
    }).isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    clearRecentContribution: PropTypes.func.isRequired,
    setReferrer: PropTypes.func.isRequired,
    isFundraisingOnLoad: PropTypes.bool.isRequired,
    fundraiser: PropTypes.string,
    user: PropTypes.shape({
        username: PropTypes.string,
    }),
    recentContribution: PropTypes.shape({
        type: PropTypes.oneOf(['pledge', 'donation']),
        amount: PropTypes.number,
    }),
};

CampaignPage.defaultProps = {
    fundraiser: null,
    user: null,
    recentContribution: null,
};

export default CampaignPage;
