import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { adopt } from 'react-adopt';
import { Query, Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import get from 'lodash/get';

import GlobalContext from 'site/context';
import FundraisePage from './fundraise-page';

const GET_CAMPAIGN = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            slug
        }
    }
`;

const GET_ROLES = gql`
    query GetRoles($id: ID!) {
        my {
            rolesFor(type: "campaign", id: $id)
        }
    }
`;

const GET_FUNDRAISER = gql`
    query FundraiserByUsername($campaign: ID!, $username: String!) {
        fundraiser: fundraiserByUsername(campaignId: $campaign, username: $username) {
            id
        }
    }
`;

const CREATE_FUNDRAISER = gql`
    mutation joinAsFundraiser($campaignId: ID!, $data: CreateFundraiserInput!) {
        joinAsFundraiser(campaignId: $campaignId, data: $data)
    }
`;

const UPDATE_FUNDRAISER = gql`
    mutation UpdateFundraiser($id: ID!, $patch: UpdateFundraiserPatchInput!) {
        updateFundraiser(id: $id, patch: $patch)
    }
`;

const CREATE_REFERRAL = gql`
    mutation createReferral($id: ID!, $data: CreateReferralInput!) {
        createReferral(id: $id, data: $data)
    }
`;

/* eslint-disable react/prop-types, react/destructuring-assignment */
const DataLoader = adopt(
    {
        campaignQuery: ({ campaign, render }) => (
            <Query query={GET_CAMPAIGN} variables={{ id: campaign }}>
                {render}
            </Query>
        ),
        fundraiserQuery: ({ campaign, username, render }) => (
            <Query skip={!username} query={GET_FUNDRAISER} variables={{ campaign, username }}>
                {render}
            </Query>
        ),
        createFundraiser: ({ campaign, render }) => (
            <Mutation
                mutation={CREATE_FUNDRAISER}
                refetchQueries={[{ query: GET_ROLES, variables: { id: campaign } }]}
                awaitRefetchQueries
            >
                {render}
            </Mutation>
        ),
        updateFundraiser: ({ render }) => (
            <Mutation mutation={UPDATE_FUNDRAISER}>{render}</Mutation>
        ),
        createReferral: ({ render }) => <Mutation mutation={CREATE_REFERRAL}>{render}</Mutation>,
    },
    ({ campaignQuery, fundraiserQuery, ...mutations }) => ({
        error: campaignQuery.error || fundraiserQuery.error,
        loading: campaignQuery.loading,
        campaign: get(campaignQuery, 'data.campaign'),
        fundraiser: get(fundraiserQuery, 'data.fundraiser'),
        ...mutations,
    })
);
/* eslint-enable react/prop-types, react/destructuring-assignment */

const FundraisePageContainer = ({ campaign: campaignId, ...props }) => {
    const { user, updateUser, signOut, showFlash, lockContributionReferrer } = useContext(
        GlobalContext
    );

    return (
        <DataLoader campaign={campaignId} username={get(user, 'username', null)}>
            {({
                error,
                loading,
                campaign,
                fundraiser,
                createFundraiser,
                updateFundraiser,
                createReferral,
            }) => {
                if (error) throw error;
                if (loading) return null;

                return (
                    <FundraisePage
                        user={user}
                        campaign={campaign}
                        fundraiser={fundraiser}
                        createFundraiser={async data => {
                            await createFundraiser({
                                variables: { campaignId: campaign.id, data },
                            });

                            lockContributionReferrer(campaign.id, user.username);
                        }}
                        updateFundraiser={patch =>
                            updateFundraiser({ variables: { id: fundraiser.id, patch } })
                        }
                        createReferral={data =>
                            createReferral({ variables: { id: fundraiser.id, data } })
                        }
                        updateUser={patch => updateUser({ userId: user.id, patch })}
                        signOut={signOut}
                        showFlash={showFlash}
                        {...props}
                    />
                );
            }}
        </DataLoader>
    );
};

FundraisePageContainer.propTypes = {
    campaign: PropTypes.string.isRequired,
};

export default FundraisePageContainer;
