import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Avatar from 'common/avatar';
import Icon from 'common/icon';
import Link from 'common/link';
import CampaignPageContext from '../../context';

const FundraiserItemPlacement = ({
    url,
    avatar,
    firstName,
    lastName,
    rank,
    statLabel,
    statValue,
}) => {
    const { theme } = useContext(CampaignPageContext);

    return (
        <Link
            to={url}
            className="block text-center rounded px-8 pb-16 mt-12 shadow-lg"
            css={{
                background: theme.colors.panelBackground,
                color: theme.colors.panelText,
            }}
        >
            <div className="inline-block -mt-12 mb-4 w-24 h-24 mx-auto relative">
                {rank && (
                    <>
                        <span className="absolute pin w-full h-full flex justify-center items-center z-30 rounded-full font-bold text-black-30 pb-2">
                            {rank}
                        </span>
                        <span className="absolute pin w-full h-full flex justify-center items-center bg-black-40 z-20 rounded-full">
                            <Icon
                                icon="trophy"
                                type="solid"
                                size="2x"
                                className={cx({
                                    'text-gold': rank === 1,
                                    'text-silver': rank === 2,
                                    'text-bronze': rank === 3,
                                })}
                            />
                        </span>
                    </>
                )}
                <Avatar
                    imageId={avatar}
                    firstName={firstName}
                    lastName={lastName}
                    className="absolute pin w-full h-full rounded-full block object-fill z-10"
                />
            </div>
            <p className="text-lg font-medium truncate">
                {firstName} {lastName}
            </p>
            <p className="font-number mt-2 mb-4" css={{ fontSize: '4.5rem' }}>
                {statValue}
            </p>
            <p
                className="text-xs font-bold uppercase tracking-wide"
                css={{ color: theme.colors.panelTextMuted }}
            >
                {statLabel}
            </p>
        </Link>
    );
};

FundraiserItemPlacement.propTypes = {
    url: PropTypes.string.isRequired,
    statLabel: PropTypes.string.isRequired,
    statValue: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    rank: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
};

FundraiserItemPlacement.defaultProps = {
    rank: null,
    firstName: '',
    lastName: '',
};

export default FundraiserItemPlacement;
