import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

import config from 'config';
import GlobalContext from 'site/context';
import FundraiseContext from '../context';
import FundraiseThanks from './fundraise-thanks';

const GET_CAMPAIGN = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            slug
            name
        }
    }
`;

const FundraiseThanksContainer = props => {
    const { campaign } = useContext(FundraiseContext);
    const { user, showFlash } = useContext(GlobalContext);

    return (
        <Query query={GET_CAMPAIGN} variables={{ id: campaign.id }}>
            {({ error, loading, data }) => {
                if (error) throw error;
                if (loading) return null;

                const campaignUrl = `${config('/hostname')}/${campaign.slug}`;
                return (
                    <FundraiseThanks
                        url={`${campaignUrl}/@${user.username}`}
                        contributeUrl={`/${campaign.slug}/contribute`}
                        message={`Support "${data.campaign.name}" on PledgeIt!`}
                        showFlash={showFlash}
                        {...props}
                    />
                );
            }}
        </Query>
    );
};

export default FundraiseThanksContainer;
