import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

import CampaignPageContext from '../context';
import CampaignHero from './campaign-hero';

const GET_HERO = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            name
            heroImage {
                id
            }
            showcase {
                prefix
                suffix
                url
                logo {
                    id
                }
            }
            recipientId
        }
    }
`;

export default props => {
    const { id } = useContext(CampaignPageContext);

    return (
        <Query query={GET_HERO} variables={{ id }}>
            {({ loading, error, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { campaign } = data;
                return <CampaignHero {...campaign} {...props} />;
            }}
        </Query>
    );
};
