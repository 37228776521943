/* eslint-disable max-classes-per-file */
export class ExtendableError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
    }
}

export class NotFoundError extends ExtendableError {
    constructor(...args) {
        super(...args);
        this.statusCode = 404;
    }
}
