import React, { useContext } from 'react';
import { adopt } from 'react-adopt';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import get from 'lodash/get';

import FundraiseContext from '../context';
import EditMessage from './fundraise-edit-message';

const GET_DEFAULT_MESSAGE = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            defaultFundraiserMessage
        }
    }
`;

const GET_FUNDRAISER_MESSAGE = gql`
    query FundraiserById($id: ID!) {
        fundraiser: fundraiserById(id: $id) {
            id
            message
        }
    }
`;

/* eslint-disable react/prop-types, react/destructuring-assignment */
const DataLoader = adopt(
    {
        campaignQuery: ({ campaign, render }) => (
            <Query query={GET_DEFAULT_MESSAGE} variables={{ id: campaign }}>
                {render}
            </Query>
        ),
        fundraiserQuery: ({ fundraiser, render }) => (
            <Query skip={!fundraiser} query={GET_FUNDRAISER_MESSAGE} variables={{ id: fundraiser }}>
                {render}
            </Query>
        ),
    },
    ({ campaignQuery, fundraiserQuery }) => ({
        error: campaignQuery.error || fundraiserQuery.error,
        loading: campaignQuery.loading || fundraiserQuery.loading,
        campaignMessage: get(campaignQuery, 'data.campaign.defaultFundraiserMessage'),
        fundraiserMessage: get(fundraiserQuery, 'data.fundraiser.message'),
    })
);
/* eslint-enable react/prop-types, react/destructuring-assignment */

export default props => {
    const { campaign, fundraiser } = useContext(FundraiseContext);

    return (
        <DataLoader campaign={campaign.id} fundraiser={get(fundraiser, 'id')}>
            {({ error, loading, campaignMessage, fundraiserMessage }) => {
                if (error) throw error;
                if (loading) return null;

                return <EditMessage message={fundraiserMessage || campaignMessage} {...props} />;
            }}
        </DataLoader>
    );
};
