import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import CampaignPageContext from '../../context';

const DetailedStats = ({
    pastPerformance,
    pastAmountPledged,
    pastAmountRaised,
    futurePerformance,
    futureAmountPledged,
    futureAmountRaised,
    performanceMetric,
}) => {
    const { theme } = useContext(CampaignPageContext);

    const formattedPastPerformance = numeral(pastPerformance).format('0,0');
    const formattedPastAmountPledged = numeral(pastAmountPledged / 100).format('$0,0');
    const formattedPastAmountRaised = numeral(pastAmountRaised / 100).format('$0,0');
    const formattedFuturePerformance = numeral(futurePerformance).format('0,0');
    const formattedFutureAmountPledged = numeral(futureAmountPledged / 100).format('$0,0');
    const formattedFutureAmountRaised = numeral(futureAmountRaised / 100).format('$0,0');

    return (
        <div>
            <div>
                <h2
                    className="text-xs font-bold uppercase tracking-wide text-center mb-4"
                    css={{ color: theme.colors.progressEarned }}
                >
                    Earned (Past performance)
                </h2>
                <div
                    className="rounded-sm px-4 border"
                    css={{
                        color: theme.colors.panelText,
                        borderColor: theme.colors.panelSectionBorder,
                        background: theme.colors.panelSectionBackground,
                    }}
                >
                    <div
                        className="flex p-2 border-b items-center"
                        css={{ borderColor: theme.colors.panelSectionBorder }}
                    >
                        <div className="flex-grow capitalize truncate pr-4 text-sm font-medium">
                            {performanceMetric.plural}
                        </div>
                        <div className="flex-no-shrink text-right font-number text-xl">
                            {formattedPastPerformance}
                        </div>
                    </div>
                    <div
                        className="flex p-2 border-b items-center"
                        css={{ borderColor: theme.colors.panelSectionBorder }}
                    >
                        <div className="flex-grow capitalize truncate pr-4 text-sm font-medium">
                            Amount Pledged*
                        </div>
                        <div className="flex-no-shrink text-right font-number text-xl">
                            {formattedPastAmountPledged}
                        </div>
                    </div>
                    <div className="flex p-2 items-center">
                        <div className="flex-grow capitalize truncate pr-4 text-sm font-medium">
                            Total Earned*
                        </div>
                        <div className="flex-no-shrink text-right font-number text-xl">
                            {formattedPastAmountRaised}
                        </div>
                    </div>
                </div>
                <p
                    className="text-xs font-medium text-center mt-4"
                    css={{ color: theme.colors.panelTextMuted }}
                >
                    * Includes flat donations
                </p>
            </div>

            <div className="mt-12">
                <h2
                    className="text-xs font-bold uppercase tracking-wide text-center mb-4"
                    css={{ color: theme.colors.progressEstimated }}
                >
                    Estimated (Future performance)
                </h2>
                <div
                    className="rounded-sm px-4 border"
                    css={{
                        color: theme.colors.panelText,
                        borderColor: theme.colors.panelSectionBorder,
                        background: theme.colors.panelSectionBackground,
                    }}
                >
                    <div
                        className="flex p-2 border-b items-center"
                        css={{ borderColor: theme.colors.panelSectionBorder }}
                    >
                        <div className="flex-grow capitalize truncate pr-4 text-sm font-medium">
                            {performanceMetric.plural}
                        </div>
                        <div className="flex-no-shrink text-right font-number text-xl">
                            {futurePerformance ? formattedFuturePerformance : '-'}
                        </div>
                    </div>
                    <div
                        className="flex p-2 border-b items-center"
                        css={{ borderColor: theme.colors.panelSectionBorder }}
                    >
                        <div className="flex-grow capitalize truncate pr-4 text-sm font-medium">
                            Amount Pledged*
                        </div>
                        <div className="flex-no-shrink text-right font-number text-xl">
                            {futureAmountPledged ? formattedFutureAmountPledged : '-'}
                        </div>
                    </div>
                    <div className="flex p-2 items-center">
                        <div className="flex-grow capitalize truncate pr-4 text-sm font-medium">
                            Total Earned*
                        </div>
                        <div className="flex-no-shrink text-right font-number text-xl">
                            {futureAmountRaised ? formattedFutureAmountRaised : '-'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

DetailedStats.propTypes = {
    pastPerformance: PropTypes.number.isRequired,
    pastAmountPledged: PropTypes.number.isRequired,
    pastAmountRaised: PropTypes.number.isRequired,
    futurePerformance: PropTypes.number,
    futureAmountPledged: PropTypes.number,
    futureAmountRaised: PropTypes.number,
    performanceMetric: PropTypes.shape({
        singular: PropTypes.string,
        plural: PropTypes.string,
    }),
};

DetailedStats.defaultProps = {
    futurePerformance: null,
    futureAmountPledged: null,
    futureAmountRaised: null,
    performanceMetric: { singular: 'result', plural: 'results' },
};

export default DetailedStats;
