import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useBreakpoint from 'hooks/use-breakpoint';
import Button from 'common/button';
import Link from 'common/link';

const CampaignPledgeButton = ({ buttonStyles, campaign, isDraft, label, theme, url }) => {
    const { lg } = useBreakpoint();
    return (
        <Button
            component={Link}
            className={cx(
                'rounded-none w-full font-bold uppercase flex flex-col justify-center h-24',
                {
                    'opacity-25 pointer-events-none': isDraft,
                    'pointer-events-none': campaign.isComplete,
                    'rounded-t-lg': lg,
                    'fixed pin-b pin-x z-40': !lg,
                }
            )}
            css={{ ...buttonStyles, ...theme.overrides.pledgeButton }}
            to={url}
        >
            {label}
        </Button>
    );
};

CampaignPledgeButton.propTypes = {
    buttonStyles: PropTypes.shape({}).isRequired,
    campaign: PropTypes.shape({
        isComplete: PropTypes.bool,
    }).isRequired,
    isDraft: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    theme: PropTypes.shape({
        overrides: PropTypes.object,
    }).isRequired,
    url: PropTypes.string.isRequired,
};

export default CampaignPledgeButton;
