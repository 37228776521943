import React from 'react';
import PropTypes from 'prop-types';
import Player from 'react-player';
import cx from 'classnames';

/**
 * Responsive wrapper for the ReactPlayer third-party
 * component. Passes extra props through to the underlying
 * Player component.
 *
 * ReactPlayer Documentation: https://github.com/CookPete/react-player
 */
const Video = ({ url, className, ...props }) => {
    if (!url) return null;

    return (
        <div
            className={cx('aspect-ratio-widescreen relative', className)}
            data-testid="video-container"
        >
            <Player
                url={url}
                className="absolute pin-x pin-y"
                width="100%"
                height="100%"
                controls
                data-testid="video-player"
                {...props}
            />
        </div>
    );
};

Video.propTypes = {
    /** Video url (supports all common video hosts.) */
    url: PropTypes.string,

    /** Additional classes for the video wrapper */
    className: PropTypes.string,
};

Video.defaultProps = {
    url: null,
    className: '',
};

export default Video;
