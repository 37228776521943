import React, { useContext } from 'react';
import qs from 'query-string';
import axios from 'axios';
import get from 'lodash/get';

import GoogleContext from 'data/services/google';
import FundraiseGoogleRefer from './fundraise-google-refer';

export default props => {
    const { currentUser } = useContext(GoogleContext);

    return (
        <FundraiseGoogleRefer
            onSearch={async query => {
                const params = qs.stringify({
                    v: '3.0',
                    alt: 'json',
                    q: query,
                    access_token: currentUser.getAuthResponse(true).access_token,
                });

                const response = await axios.get(
                    `https://www.google.com/m8/feeds/contacts/default/full/?${params}`
                );

                return get(response, 'data.feed.entry', [])
                    .map(result => ({
                        firstName: get(result, 'gd$name.gd$givenName.$t'),
                        lastName: get(result, 'gd$name.gd$familyName.$t', ''),
                        email: get(result, 'gd$email.0.address'),
                    }))
                    .filter(x => x.firstName && x.firstName.toLowerCase() !== 'unknown' && x.email);
            }}
            {...props}
        />
    );
};
