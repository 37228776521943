import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import config from 'config';

/** Displays text in a skewed style, used for
 * important headlines, etc. */
const SkewText = ({ component: Component, backgroundColor, textColor, className, children }) => {
    if (!children) return null;

    return (
        <Component
            className={className}
            css={css`
                transform: skew(-10deg);
                transform-origin: left;
            `}
            data-testid="skewtext-container"
        >
            <span className="inline-block my-1 max-w-full break-words">
                <span
                    css={{
                        color: textColor,
                        background: backgroundColor,
                        boxShadow: `-0.25em 0 0 ${backgroundColor}, 0.25em 0 0 ${backgroundColor}, inset 1px 1px 1px 3px ${backgroundColor}`,
                    }}
                    className="inline"
                    data-testid="skewtext"
                >
                    {children}
                </span>
            </span>
        </Component>
    );
};

SkewText.propTypes = {
    /** Background color */
    backgroundColor: PropTypes.string,

    /** Text color */
    textColor: PropTypes.string,

    /** Outer component (typically a headline) */
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

    /** @ignore */
    className: PropTypes.string,

    /** @ignore */
    children: PropTypes.node,
};

SkewText.defaultProps = {
    backgroundColor: config('/tailwind/colors/green'),
    textColor: config('/tailwind/colors/white'),
    component: 'p',
    className: '',
    children: null,
};

export default SkewText;
