import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TinyColor } from '@ctrl/tinycolor';
import Collapsible from 'common/collapsible';
import Button from 'common/button';
import Divider from 'common/divider';
import posed from 'react-pose';

import { CampaignPageConsumer } from '../context';

const ContentPose = posed.div({
    collapsed: { height: x => x.collapsedHeight, transition: { duration: 800 } },
    expanded: { height: 'auto', transition: { duration: 800 } },
});

class CampaignContentMask extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
            .isRequired,
        closedText: PropTypes.string,
        dividerClassName: PropTypes.string,
        height: PropTypes.number.isRequired,
        openText: PropTypes.string,
    };

    static defaultProps = {
        closedText: 'Show More',
        dividerClassName: 'h-32',
        openText: 'Collapse',
    };

    constructor(props) {
        super(props);
        this.content = createRef();
    }

    state = {
        truncate: false,
    };

    componentDidMount() {
        this.checkForTruncate();
    }

    checkForTruncate = () => {
        const { height } = this.props;
        const currentHeight = this.content.current.clientHeight;

        if (currentHeight === 0) {
            setTimeout(this.checkForTruncate, 100);
            return;
        }

        this.setState({ truncate: currentHeight > height });
    };

    render() {
        const { children, closedText, dividerClassName, height, openText } = this.props;
        const { truncate } = this.state;

        const modifyColor = color => new TinyColor(color);

        return (
            <CampaignPageConsumer>
                {({ theme }) => {
                    const alphaBg = x =>
                        modifyColor(theme.colors.background)
                            .setAlpha(x)
                            .toRgbString();
                    return (
                        <Collapsible startCollapsed>
                            {({ toggle, isCollapsed }) => (
                                <ContentPose
                                    collapsedHeight={height}
                                    pose={isCollapsed && truncate ? 'collapsed' : 'expanded'}
                                    className="relative overflow-hidden"
                                >
                                    <div className={cx({ 'pb-12': truncate })} ref={this.content}>
                                        {children}
                                    </div>
                                    {truncate && (
                                        <div
                                            className={cx(
                                                dividerClassName,
                                                'absolute pin-x pin-b text-center flex flex-col justify-end'
                                            )}
                                            css={{
                                                background:
                                                    isCollapsed &&
                                                    `linear-gradient(
                                                    to bottom,
                                                    ${alphaBg(0)},
                                                    ${alphaBg(0.95)} 50%,
                                                    ${alphaBg(1)}
                                                );`,
                                            }}
                                        >
                                            <Divider color={theme.colors.border}>
                                                <Button
                                                    onClick={toggle}
                                                    className="uppercase text-2xs font-bold tracking-wide"
                                                    css={{
                                                        color: theme.colors.link,
                                                        '&:hover': {
                                                            color: theme.colors.linkHover,
                                                        },
                                                    }}
                                                >
                                                    {!isCollapsed ? openText : closedText}
                                                </Button>
                                            </Divider>
                                        </div>
                                    )}
                                </ContentPose>
                            )}
                        </Collapsible>
                    );
                }}
            </CampaignPageConsumer>
        );
    }
}

export default CampaignContentMask;
