import React from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { adopt } from 'react-adopt';
import get from 'lodash/get';

import { GlobalConsumer } from 'site/context';
import CampaignContact from './campaign-contact';
import { CampaignPageConsumer } from '../context';

const CONTACT_CAMPAIGN = gql`
    mutation contactCampaign($id: ID!, $email: String!, $message: String!) {
        contactCampaign(id: $id, email: $email, message: $message)
    }
`;
const REPORT_CAMPAIGN = gql`
    mutation reportCampaign($id: ID!, $email: String!, $message: String!) {
        reportCampaign(id: $id, email: $email, message: $message)
    }
`;
/* eslint-disable react/destructuring-assignment, react/prop-types */
const CampaignContactState = adopt(
    {
        context: <CampaignPageConsumer />,
        global: <GlobalConsumer />,
        contactCampaign: ({ render }) => <Mutation mutation={CONTACT_CAMPAIGN}>{render}</Mutation>,
        reportCampaign: ({ render }) => <Mutation mutation={REPORT_CAMPAIGN}>{render}</Mutation>,
    },
    ({ context, contactCampaign, global, reportCampaign }) => ({
        ...context,
        contactCampaign,
        authEmail: get(global, 'user.email'),
        reportCampaign,
        showFlash: global.showFlash,
    })
);
/* eslint-enable */

const CampaignContactContainer = () => (
    <CampaignContactState>
        {({
            error,
            loading,
            authEmail,
            contactCampaign,
            id,
            reportCampaign,
            showFlash,
            ...props
        }) => {
            if (error) throw error;
            if (loading) return null;

            return (
                <CampaignContact
                    authEmail={authEmail}
                    contactCampaign={({ email, message }) =>
                        contactCampaign({ variables: { email, id, message } })
                    }
                    reportCampaign={({ email, message }) =>
                        reportCampaign({ variables: { email, id, message } })
                    }
                    showFlash={showFlash}
                    {...props}
                />
            );
        }}
    </CampaignContactState>
);

export default CampaignContactContainer;
