import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from 'common/button';
import Icon from 'common/icon';

const OauthSignIn = ({ onFacebook, onGoogle, className }) => (
    <div className={cx('flex -ml-4 sm:flex-col', className)}>
        <div className="w-1/2 ml-4 sm:w-full">
            <Button
                color="facebook"
                size="md"
                className="block w-full sm:mb-2 text-14 font-medium"
                data-testid="facebook"
                onClick={onFacebook}
            >
                <Icon icon="facebook-f" type="brand" size="lg" />
                <span className="ml-5 font-bold">Facebook</span>
            </Button>
        </div>
        <div className="w-1/2 ml-4 sm:w-full">
            <Button
                color="google"
                size="md"
                className="block w-full text-14 font-medium"
                data-testid="google"
                onClick={onGoogle}
            >
                <Icon icon="google" type="brand" size="lg" />
                <span className="ml-5 font-bold">Google</span>
            </Button>
        </div>
    </div>
);

OauthSignIn.propTypes = {
    onFacebook: PropTypes.func.isRequired,
    onGoogle: PropTypes.func.isRequired,
    className: PropTypes.string,
};

OauthSignIn.defaultProps = {
    className: '',
};

export default OauthSignIn;
