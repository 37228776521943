import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import config from 'config';
import { fullName } from 'utils';
import GlobalContext from 'site/context';
import Avatar from 'common/avatar';
import Button from 'common/button';
import Icon from 'common/icon';
import FundraiseContext from '../context';

const FundraiseConfirm = ({ onChangeUser, onSubmit }) => {
    const { user, showFlash } = useContext(GlobalContext);
    const { campaign } = useContext(FundraiseContext);
    const [isSubmitting, setSubmitting] = useState(false);

    const urlBase = `${config('/hostname')}/${campaign.slug}/`;
    const fullUrl = `${urlBase}@${user.username}`;

    return (
        <>
            <div className="relative p-8 pt-14 rounded shadow-md mt-16 mx-auto w-4/5">
                <div className="absolute pin-t pin-x -mt-12">
                    <Avatar
                        firstName={user.firstName}
                        lastName={user.lastName}
                        imageId={user.avatar.id}
                        className="w-24 h-24 block mx-auto rounded-full"
                    />
                </div>
                <p className="text-18 font-medium leading-snug text-gray-70">
                    {fullName(user.firstName, user.lastName)}
                </p>
                <p className="text-14 font-medium leading-snug text-gray-40 mt-2">{user.email}</p>
            </div>
            <div className="text-center mt-4">
                <button
                    type="button"
                    className="link-green text-12 font-medium inline-block"
                    onClick={onChangeUser}
                >
                    Sign into a different account
                </button>
            </div>
            <div className="mt-8 text-center">
                <h2 className="text-10 font-bold uppercase tracking-wide text-gray-50 mb-4">
                    Your custom url will be
                </h2>
                <CopyToClipboard
                    text={fullUrl}
                    onCopy={() =>
                        showFlash({
                            type: 'info',
                            iconName: 'paperclip',
                            iconStyle: 'regular',
                            title: 'Copied!',
                            message: 'Campaign url has been copied to your clipboard.',
                            duration: 3000,
                        })
                    }
                >
                    <button type="button" className="block w-full group">
                        <span className="block text-14 font-medium leading-snug break-words text-gray-40 group-hover:text-gray-60 transition">
                            {urlBase}
                            <span className="text-green-50 group-hover:text-green-60 break-words transition">
                                @{user.username}
                            </span>
                        </span>
                        <span className="block text-12 font-medium leading-snug mt-2 text-gray-40 group-hover:text-gray-60 transition">
                            Click or tap to copy
                        </span>
                    </button>
                </CopyToClipboard>
            </div>
            <div className="mt-12">
                <Button
                    type="button"
                    color="green"
                    size="md"
                    className="text-14 font-medium block w-full"
                    onClick={() => onSubmit({ setSubmitting })}
                >
                    {isSubmitting ? (
                        <Icon icon="spinner" size="lg" className="leading-none" spin />
                    ) : (
                        'Confirm my information'
                    )}
                </Button>
            </div>
        </>
    );
};

FundraiseConfirm.propTypes = {
    onChangeUser: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default FundraiseConfirm;
