import React from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Image } from 'cloudinary-react';

import Icon from 'common/icon';
import Button from 'common/button';
import Link from 'common/link';

const FundraiseThanks = ({ url, contributeUrl, message, showFlash, onComplete }) => (
    <>
        <div className="w-64 h-64 block my-4 mx-auto">
            <Image publicId="fundraise-step2" />
        </div>
        <p className="text-base leading-normal my-4">
            You believe in this campaign. If you don&apos;t pledge, how can you expect others to
            support you?
        </p>
        <div className="my-8">
            <h2 className="text-11 font-bold text-center uppercase tracking-wide">
                Share your url
            </h2>
            <div className="mt-4 flex justify-center items-center text-21">
                <FacebookShareButton
                    url={url}
                    className="text-facebook transition w-14 h-14 flex justify-center items-center mx-3 hover:opacity-50"
                >
                    <Icon icon="facebook-f" type="brand" size="lg" />
                </FacebookShareButton>
                <TwitterShareButton
                    url={url}
                    title={message}
                    className="text-twitter transition w-14 h-14 flex justify-center items-center mx-3 hover:opacity-50"
                >
                    <Icon icon="twitter" type="brand" size="lg" />
                </TwitterShareButton>
                <CopyToClipboard
                    text={url}
                    className="mx-3 text-gray-70 transition hover:opacity-50"
                    onCopy={() =>
                        showFlash({
                            type: 'info',
                            iconName: 'paperclip',
                            iconStyle: 'regular',
                            title: 'Copied!',
                            message: 'Campaign url has been copied to your clipboard.',
                            duration: 3000,
                        })
                    }
                >
                    <button type="button" className="w-14 h-14 flex justify-center items-center">
                        <Icon icon="paperclip" size="lg" />
                    </button>
                </CopyToClipboard>
            </div>
        </div>

        <div className="mt-8">
            <Button
                component={Link}
                color="green"
                size="md"
                className="text-14 font-medium block w-full"
                to={contributeUrl}
                onClick={onComplete}
            >
                Make the first pledge
            </Button>
            <Button
                type="button"
                size="md"
                className="text-14 font-medium block w-full link-gray"
                onClick={onComplete}
            >
                Maybe later
            </Button>
        </div>
    </>
);

FundraiseThanks.propTypes = {
    url: PropTypes.string.isRequired,
    contributeUrl: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    showFlash: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
};

export default FundraiseThanks;
