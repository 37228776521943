import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import HTML from 'common/html';

const sideBySide = css`
    .campaignPage-content-sideBySide {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 768px) {
            flex-direction: row;
        }
    }

    .campaignPage-content-sideBySide-side {
        flex: 1 0 auto;
        @media (min-width: 768px) {
            width: 50%;
        }
    }

    .campaignPage-content-sideBySide img {
        display: block;
        max-width: 100%;
    }
`;

const CampaignHighlight = ({ campaign, theme }) => (
    <div className="mt-20" css={[sideBySide, theme.overrides.highlight]}>
        <HTML>{campaign.highlight}</HTML>
    </div>
);

CampaignHighlight.propTypes = {
    campaign: PropTypes.shape({
        highlight: PropTypes.string,
    }).isRequired,
    theme: PropTypes.shape({
        overrides: PropTypes.object,
    }).isRequired,
};

export default CampaignHighlight;
