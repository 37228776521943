import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const FundraiseContext = createContext();

export const FundraiseProvider = ({ activePage, campaign, fundraiser, children }) => {
    // We wrap the state object that we're going to expose in a
    // useMemo call to prevent unnecessary re-renders
    const value = useMemo(
        () => ({
            activePage,
            campaign,
            fundraiser,
        }),
        [activePage, fundraiser]
    );

    return <FundraiseContext.Provider value={value}>{children}</FundraiseContext.Provider>;
};

FundraiseProvider.propTypes = {
    children: PropTypes.node.isRequired,
    activePage: PropTypes.string.isRequired,
    campaign: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
    }).isRequired,
    fundraiser: PropTypes.shape({
        id: PropTypes.string,
    }),
};

FundraiseProvider.defaultProps = {
    fundraiser: null,
};

export const FundraiseConsumer = FundraiseContext.Consumer;
export default FundraiseContext;
