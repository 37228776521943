import React from 'react';
import PropTypes from 'prop-types';

const CampaignHeadline = ({ campaign, theme }) => (
    <h2
        className="text-lg md:text-xl font-bold leading-tight mb-6"
        css={{
            color: theme.colors.text,
            ...theme.overrides.headline,
        }}
    >
        {campaign.headline}
    </h2>
);

CampaignHeadline.propTypes = {
    campaign: PropTypes.shape({
        headline: PropTypes.string,
    }).isRequired,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            text: PropTypes.string,
        }),
        overrides: PropTypes.object,
    }).isRequired,
};

export default CampaignHeadline;
