import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';

import config from 'config';
import Link from 'common/link';

import CampaignPageContext from '../../context';

const HeroShowcase = ({ prefix, suffix, url, logo, onImage }) => {
    const { theme } = useContext(CampaignPageContext);

    const Wrapper = url ? Link : 'span';

    return (
        <Wrapper
            to={url}
            external
            className="inline-flex flex-col md:flex-row justify-start items-center text-xs uppercase font-bold tracking-wide transition mb-0 mt-4 lg:mb-8 lg:mt-0"
            css={{
                color: onImage ? config('/tailwind/colors/white') : theme.colors.text,
                textShadow: onImage ? `0 0 1px ${config('/tailwind/colors/black60')}` : 'none',
                opacity: 1,
                '&:hover': {
                    opacity: url ? 0.8 : 1,
                },
            }}
        >
            {prefix && <span className="block mb-3 md:inline-block md:mb-0 md:mr-4">{prefix}</span>}
            {logo && (
                <Image
                    publicId={logo.id}
                    transformation="logo"
                    className="inline-block max-h-16 md:max-h-24"
                />
            )}
            {suffix && <span className="block mt-3 md:inline-block md:mt-0 md:ml-4">{suffix}</span>}
        </Wrapper>
    );
};

HeroShowcase.propTypes = {
    onImage: PropTypes.bool.isRequired,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    url: PropTypes.string,
    logo: PropTypes.shape({
        id: PropTypes.string,
    }),
};

HeroShowcase.defaultProps = {
    prefix: null,
    suffix: null,
    url: null,
    logo: null,
};

export default HeroShowcase;
