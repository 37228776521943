import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';

import useBreakpoint from 'hooks/use-breakpoint';
import Container from 'common/container';
import Hero from 'common/hero';
import CampaignPageContext from '../context';
import CampaignHeroImage from './image';
import CampaignHeroOverlay from './overlay';
import CampaignHeroTitle from './title';
import CampaignHeroProceeds from './proceeds';
import CampaignHeroShowcase from './showcase';

const HeroPose = posed.div({
    enter: { opacity: 1, staggerChildren: 250 },
    exit: { opacity: 0, staggerChildren: 250 },
});

const TextPose = posed.div({
    enter: { y: 0, opacity: 1, transition: { duration: 250 } },
    exit: { y: 50, opacity: 0, transition: { duration: 250 } },
});

const initialTextStyles = {
    transform: 'translateX(50)',
    opacity: 0,
};

const CampaignHero = ({ name, heroImage, showcase, recipientId }) => {
    const { theme } = useContext(CampaignPageContext);
    const { lg } = useBreakpoint();

    return (
        <PoseGroup key={lg ? 'hero-lg' : 'hero-sm'} withParent={false} animateOnMount>
            <HeroPose key="campaign-hero" className="opacity-0">
                {lg ? (
                    <Hero
                        className="flex-1 flex flex-col justify-end bg-black-20"
                        image={heroImage ? <CampaignHeroImage publicId={heroImage.id} /> : null}
                        overlay={<CampaignHeroOverlay />}
                    >
                        <Container className="max-w-xxxl text-center lg:text-left pb-48">
                            {showcase && (
                                <TextPose css={initialTextStyles}>
                                    <CampaignHeroShowcase {...showcase} onImage />
                                </TextPose>
                            )}
                            <TextPose css={initialTextStyles}>
                                <CampaignHeroTitle>{name}</CampaignHeroTitle>
                            </TextPose>
                            {recipientId && (
                                <TextPose css={initialTextStyles}>
                                    <CampaignHeroProceeds shadow />
                                </TextPose>
                            )}
                        </Container>
                    </Hero>
                ) : (
                    <>
                        {heroImage && (
                            <CampaignHeroImage
                                className="aspect-ratio-short"
                                publicId={heroImage.id}
                            />
                        )}
                        <Container className="max-w-xxxl text-center mt-8">
                            <TextPose css={initialTextStyles}>
                                <CampaignHeroTitle>{name}</CampaignHeroTitle>
                            </TextPose>

                            {recipientId && (
                                <TextPose css={initialTextStyles}>
                                    <CampaignHeroProceeds color={theme.colors.text} />
                                </TextPose>
                            )}
                            {showcase && (
                                <TextPose css={initialTextStyles}>
                                    <CampaignHeroShowcase {...showcase} onImage={false} />
                                </TextPose>
                            )}
                        </Container>
                    </>
                )}
            </HeroPose>
        </PoseGroup>
    );
};

CampaignHero.propTypes = {
    name: PropTypes.string,
    recipientId: PropTypes.string,
    heroImage: PropTypes.shape({
        id: PropTypes.string,
    }),
    showcase: PropTypes.shape({}),
};

CampaignHero.defaultProps = {
    name: null,
    recipientId: null,
    heroImage: null,
    showcase: null,
};

export default CampaignHero;
