import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import uniqueId from 'lodash/uniqueId';
import pluralize from 'pluralize';

import Icon from 'common/icon';

export default class GoogleContactList extends Component {
    static propTypes = {
        activeCheck: PropTypes.func.isRequired,
        isShowingResults: PropTypes.bool.isRequired,
        searchResults: PropTypes.arrayOf(
            PropTypes.shape({
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                email: PropTypes.string,
            })
        ),
        selectedContacts: PropTypes.arrayOf(
            PropTypes.shape({
                firstName: PropTypes.string,
                familyName: PropTypes.string,
                email: PropTypes.string,
            })
        ),
        isShowingActive: PropTypes.bool.isRequired,
        onShowSelected: PropTypes.func.isRequired,
        onClickContact: PropTypes.func.isRequired,
    };

    static defaultProps = {
        searchResults: [],
        selectedContacts: [],
    };

    renderContacts = version => {
        const { activeCheck, onClickContact } = this.props;
        return (
            <div className="flex flex-col my-8">
                {version.map((contact, index) => {
                    const activeItem = activeCheck(contact.email);
                    return (
                        <div
                            className={cx(
                                'flex flex-row justify-between py-6 border-t border-gray-20 cursor-pointer hover:bg-gray-10 ',
                                {
                                    'border-none': index === 0,
                                }
                            )}
                            key={uniqueId('contact')}
                            onClick={() => onClickContact(contact)}
                            role="presentation"
                            data-testid="contactList"
                        >
                            <div
                                className={cx(
                                    'sm:text-sm flex-grow truncate px-2 text-left text-gray-70',
                                    {
                                        'px-4': activeItem,
                                    }
                                )}
                                data-testid="contactItem"
                            >
                                {activeItem && (
                                    <span className="text-xl">
                                        <Icon
                                            icon="check-square"
                                            type="solid"
                                            fixedWidth
                                            className="text-green align-middle"
                                            data-testid="contactIcon"
                                        />
                                    </span>
                                )}
                                <span
                                    className={cx('ml-2', {
                                        'text-green': activeItem,
                                    })}
                                >
                                    <span className="font-medium" data-testid="contactNames">
                                        {contact.firstName} {contact.lastName}
                                    </span>{' '}
                                    <span data-testid="contactEmail">{`(${contact.email})`}</span>
                                </span>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    render() {
        const {
            isShowingResults,
            searchResults,
            selectedContacts,
            isShowingActive,
            onShowSelected,
        } = this.props;
        return (
            <div data-testid="componentLists">
                {selectedContacts.length > 0 && !isShowingActive && (
                    <h5 className="mt-4 text-green cursor-pointer" onClick={onShowSelected}>
                        View {selectedContacts.length} selected{' '}
                        {pluralize('contact', selectedContacts.length)}
                    </h5>
                )}

                <div className="max-h-64 overflow-y-auto">
                    {searchResults.length > 0 &&
                        !isShowingActive &&
                        isShowingResults &&
                        this.renderContacts(searchResults)}

                    {isShowingActive && this.renderContacts(selectedContacts)}

                    {searchResults.length === 0 && isShowingResults && !isShowingActive && (
                        <div className="italic text-center my-8 py-2" data-testid="empty-message">
                            No Contacts Found
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
