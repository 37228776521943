import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { DateTime } from 'luxon';

import CampaignContributorActions from './contributor-actions';

import CampaignPageContext from '../context';

const GET_CONTRIBUTION = gql`
    query GetContribution($campaign: ID!) {
        my {
            contribution: contributionToCampaign(campaign: $campaign) {
                id
                chits {
                    type
                    amount
                    createdAt
                }
            }
        }
    }
`;

const CampaignContributorActionsContainer = () => {
    const { id, theme } = useContext(CampaignPageContext);

    return (
        <Query query={GET_CONTRIBUTION} variables={{ campaign: id }}>
            {({ loading, error, data }) => {
                if (error) throw error;
                if (loading) return null;

                const [contribution] = get(data, 'my.contribution.chits', []);
                if (!contribution) return null;

                const action = contribution.type === 'pledge' ? 'pledged' : 'donated';
                const formattedDate = DateTime.fromISO(contribution.createdAt).toLocaleString(
                    DateTime.DATE_MED
                );

                const url = `/manage/campaigns/${id}/contributions`;

                return (
                    <CampaignContributorActions
                        action={action}
                        formattedDate={formattedDate}
                        theme={theme}
                        url={url}
                    />
                );
            }}
        </Query>
    );
};

export default CampaignContributorActionsContainer;
