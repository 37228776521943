import React from 'react';
import PropTypes from 'prop-types';

const ContactsSearchBox = ({ onChange, value }) => (
    <div>
        <input
            type="text"
            name="search"
            placeholder="Search contacts..."
            className="input-default w-full"
            value={value}
            onChange={e => onChange(e.target.value)}
        />
    </div>
);

ContactsSearchBox.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

ContactsSearchBox.defaultProps = {
    value: '',
};

export default ContactsSearchBox;
