import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import cx from 'classnames';

const heroBackground = css`
    div {
        display: block;
        width: 100%;
        height: 100%;
    }
`;

/**
 * Page hero component
 */
const Hero = ({ size, image, overlay, className, children }) => (
    <div
        className="relative flex flex-col justify-center"
        css={css`
            min-height: ${size === 'sm' ? '35vh' : '80vh'};
        `}
        data-testid="hero"
    >
        {children && (
            <div className={cx('relative z-20', className)} data-testid="hero-children">
                {children}
            </div>
        )}
        {image && (
            <div className="absolute pin-x pin-y z-0" css={heroBackground} data-testid="hero-image">
                {image}
            </div>
        )}
        {overlay && (
            <div
                className="absolute pin-x pin-y z-10"
                css={heroBackground}
                data-testid="hero-overlay"
            >
                {overlay}
            </div>
        )}
    </div>
);

Hero.propTypes = {
    /** Size (height) of the hero */
    size: PropTypes.oneOf(['sm', 'lg']),

    /** Image to be used in the background */
    image: PropTypes.node,

    /** Color overlay in background (above image) */
    overlay: PropTypes.node,

    /** Hero content */
    children: PropTypes.node,

    /** @ignore */
    className: PropTypes.string,
};

Hero.defaultProps = {
    size: 'lg',
    image: null,
    overlay: null,
    children: null,
    className: '',
};

export default Hero;
