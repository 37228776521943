import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import sortBy from 'lodash/sortBy';

import CampaignSchedule from './campaign-schedule';
import CampaignPageContext from '../context';

const GET_PERFORMANCES = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            isDonationOnly
            eventName {
                plural
            }
            metricName {
                plural
            }
            stats {
                pledgeValue
            }
            performances {
                id
                result
                isFinal
                event {
                    name
                    startDate
                }
            }
        }
    }
`;

const CampaignScheduleContainer = () => {
    const { id, theme } = useContext(CampaignPageContext);

    return (
        <Query query={GET_PERFORMANCES} variables={{ id }}>
            {({ loading, error, data }) => {
                if (error) throw error;

                if (loading) return null;

                const { campaign = {} } = data;
                const { performances = [] } = campaign;

                if (campaign.isDonationOnly || !performances || !performances.length) {
                    return null;
                }

                return (
                    <CampaignSchedule
                        campaign={campaign}
                        performances={sortBy(performances, [
                            performance => performance.event.startDate,
                        ])}
                        theme={theme}
                    />
                );
            }}
        </Query>
    );
};

export default CampaignScheduleContainer;
