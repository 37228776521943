import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Image } from 'cloudinary-react';

import Icon from 'common/icon';
import Link from 'common/link';
import Button from 'common/button';

const CampaignRecipient = ({ hasLogo, recipient, theme }) => (
    <div
        className={cx(
            'p-12 rounded-lg shadow-lg md:flex flex-row-reverse items-center text-center mt-20',
            {
                'md:max-w-2/3 md:mx-auto': !hasLogo,
                'md:text-left': hasLogo,
            }
        )}
        css={{
            background: theme.colors.panelBackground,
            ...theme.overrides.recipient,
        }}
    >
        {hasLogo && (
            <div className="flex-no-shrink md:w-1/3 mb-8 md:mb-0">
                <Image
                    publicId={recipient.logo.id}
                    alt={recipient.name}
                    className="block max-h-64 max-w-full mx-auto"
                />
            </div>
        )}
        <div className={cx('flex-grow', { 'md:pr-12': hasLogo })}>
            <p
                className="text-2xs uppercase tracking-wide font-bold"
                css={{
                    color: theme.colors.panelTextMuted,
                    ...theme.overrides.recipientLeadIn,
                }}
            >
                This campaign benefits
            </p>
            {recipient.name && (
                <h2
                    className="text-lg font-bold mt-2"
                    css={{
                        color: theme.colors.panelText,
                        ...theme.overrides.recipientName,
                    }}
                >
                    {recipient.name}
                </h2>
            )}
            {recipient.isVerified && (
                <p className="text-blue text-sm font-medium mt-4">
                    <Icon icon="badge-check" type="solid" size="lg" className="mr-2" />
                    Verified {recipient.type === 'charity' ? 'Charity' : 'Organization'}
                </p>
            )}
            {recipient.description && (
                <p
                    className="leading-loose mt-em"
                    css={{
                        color: theme.colors.panelText,
                        ...theme.overrides.recipientDescription,
                    }}
                >
                    {recipient.description}
                </p>
            )}
            {recipient.isTaxDeductible && (
                <p
                    className="mt-em"
                    css={{
                        color: theme.colors.panelTextMuted,
                        ...theme.overrides.recipientTaxStatement,
                    }}
                >
                    Contributions made to this campaign are tax-deductible.
                </p>
            )}
            {recipient.url && (
                <Button
                    component={Link}
                    to={recipient.url}
                    external
                    color="blue"
                    size="md"
                    className="font-bold text-sm mt-12"
                >
                    View Website
                </Button>
            )}
        </div>
    </div>
);

CampaignRecipient.propTypes = {
    hasLogo: PropTypes.bool.isRequired,
    recipient: PropTypes.shape({
        name: PropTypes.string,
        isVerified: PropTypes.bool,
        isTaxDeductible: PropTypes.bool,
        type: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        logo: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            panelBackground: PropTypes.string,
            panelTextMuted: PropTypes.string,
            panelText: PropTypes.string,
        }),
        overrides: PropTypes.object,
    }).isRequired,
};

export default CampaignRecipient;
