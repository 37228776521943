import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

import CampaignHeadline from './campaign-headline';

import CampaignPageContext from '../context';

const GET_HEADLINE = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            headline
        }
    }
`;

const CampaignHeadlineContainer = () => {
    const { id, theme } = useContext(CampaignPageContext);

    return (
        <Query query={GET_HEADLINE} variables={{ id }}>
            {({ error, loading, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { campaign } = data;
                if (!campaign.headline) return null;

                return <CampaignHeadline campaign={campaign} theme={theme} />;
            }}
        </Query>
    );
};

export default CampaignHeadlineContainer;
