import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const ModalContext = createContext();
export const ModalProvider = ({ children, id }) => {
    const value = useMemo(
        () => ({
            id,
        }),
        [id]
    );
    return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

ModalProvider.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export const ModalConsumer = ModalContext.Consumer;
export default ModalContext;
