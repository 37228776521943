import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import cx from 'classnames';

import Icon from 'common/icon';

class EditableFundraiserMessage extends Component {
    static propTypes = {
        message: PropTypes.string.isRequired,
        onUpdate: PropTypes.func.isRequired,
        showFlash: PropTypes.func.isRequired,
        theme: PropTypes.shape({
            colors: PropTypes.shape({
                buttonBackground: PropTypes.string,
                buttonHoverBackground: PropTypes.string,
            }),
        }),
    };

    static defaultProps = {
        theme: {},
    };

    state = {
        isEditing: false,
    };

    handleEditClick = () => this.setState({ isEditing: true });

    handleSubmit = async (...args) => {
        const { onUpdate, showFlash } = this.props;

        try {
            await onUpdate(...args);
            this.setState({ isEditing: false });
            showFlash({
                type: 'info',
                title: 'Success!',
                message: 'Your new personal message has been saved.',
                duration: 3000,
            });
        } catch (err) {
            console.log('Error saving fundraiser message', err);

            showFlash({
                type: 'danger',
                title: 'Error saving message',
                message: 'We were unable the changes to your personal message. Please try again.',
                duration: 5000,
            });
        }
    };

    render() {
        const { isEditing } = this.state;
        const { message, theme } = this.props;

        if (!isEditing) {
            return (
                <div>
                    <p className="cursor-text" onClick={this.handleEditClick}>
                        {message}
                    </p>
                    <button
                        type="button"
                        className="text-sm link-green mt-4"
                        onClick={this.handleEditClick}
                        css={{
                            color: theme.colors.buttonBackground,
                            '&:hover': {
                                color: theme.colors.buttonHoverBackground,
                            },
                        }}
                    >
                        <Icon icon="pencil" size="1x" className="mr-2" />
                        Edit message
                    </button>
                </div>
            );
        }

        return (
            <Formik
                initialValues={{ message }}
                validationSchema={yup.object().shape({ message: yup.string().required() })}
                onSubmit={this.handleSubmit}
            >
                {({ submitForm, isSubmitting }) => (
                    <Form>
                        <p>
                            <Field
                                component="textarea"
                                name="message"
                                onBlur={submitForm}
                                className={cx(
                                    'inherit-all w-full h-64 py-3 px-6 -my-3 -mx-6 rounded bg-black-10',
                                    { 'opacity-60 pointer-events-none': isSubmitting }
                                )}
                                autoFocus
                            />
                        </p>
                        <button type="submit" className="hidden" />
                    </Form>
                )}
            </Formik>
        );
    }
}

export default EditableFundraiserMessage;
