import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

import CampaignHighlight from './campaign-highlight';

import CampaignPageContext from '../context';

const GET_HIGHLIGHT = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            highlight
        }
    }
`;

const CampaignHighlightContainer = () => {
    const { id, theme } = useContext(CampaignPageContext);

    return (
        <Query query={GET_HIGHLIGHT} variables={{ id }}>
            {({ error, loading, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { campaign } = data;
                if (!campaign.highlight) return null;

                return <CampaignHighlight campaign={campaign} theme={theme} />;
            }}
        </Query>
    );
};

export default CampaignHighlightContainer;
