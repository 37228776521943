import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import { adopt } from 'react-adopt';
import gql from 'graphql-tag';
import get from 'lodash/get';
import numeral from 'numeral';

import CampaignPageContext from '../context';
import CampaignFundraisers from './campaign-fundraisers';

const GET_MY_ROLES = gql`
    query CampaignRoles($id: ID!) {
        my {
            roles: rolesFor(type: "campaign", id: $id)
        }
    }
`;

const GET_CAMPAIGN = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            slug
            isComplete
            enableFundraisers
        }
    }
`;

const GET_FUNDRAISERS = gql`
    query FundraisersByCampaign($id: ID!) {
        fundraiserList: fundraisersByCampaign(
            campaignId: $id
            sort: "-stats.amountRaisedEstimate"
            limit: 0
        ) {
            fundraisers: items {
                id
                stats {
                    amountRaisedEstimate
                }
                user {
                    id
                    firstName
                    lastName
                    username
                    avatar {
                        id
                    }
                }
            }
        }
    }
`;

/* eslint-disable react/prop-types, react/destructuring-assignment */
const DataLoader = adopt(
    {
        roleQuery: ({ id, render }) => (
            <Query query={GET_MY_ROLES} variables={{ id }}>
                {render}
            </Query>
        ),
        campaignQuery: ({ id, render }) => (
            <Query query={GET_CAMPAIGN} variables={{ id }}>
                {render}
            </Query>
        ),
        fundraiserQuery: ({ id, render }) => (
            <Query query={GET_FUNDRAISERS} variables={{ id }}>
                {render}
            </Query>
        ),
    },
    ({ roleQuery, campaignQuery, fundraiserQuery }) => ({
        error: roleQuery.error || campaignQuery.error || fundraiserQuery.error,
        loading: roleQuery.loading || campaignQuery.loading || fundraiserQuery.loading,
        roles: get(roleQuery, 'data.my.roles', []),
        campaign: get(campaignQuery, 'data.campaign'),
        fundraisers: get(fundraiserQuery, 'data.fundraiserList.fundraisers', []),
    })
);
/* eslint-enable react/prop-types, react/destructuring-assignment */

export default () => {
    const { id } = useContext(CampaignPageContext);

    return (
        <DataLoader id={id}>
            {({ error, loading, campaign, fundraisers, roles }) => {
                if (error) throw error;
                if (loading) return null;

                return (
                    <CampaignFundraisers
                        campaign={campaign}
                        fundraisers={fundraisers.map((fundraiser, i) => {
                            const amountRaised = get(fundraiser, 'stats.amountRaisedEstimate', 0);
                            return {
                                ...fundraiser,
                                rank: amountRaised ? i + 1 : null,
                                url: `/${campaign.slug}/@${fundraiser.user.username}`,
                                formattedAmountRaised: amountRaised
                                    ? numeral(amountRaised / 100).format('$0,0')
                                    : '-',
                            };
                        })}
                        shouldDisplayCTA={
                            !roles.includes('fundraiser') &&
                            !campaign.isComplete &&
                            campaign.enableFundraisers
                        }
                    />
                );
            }}
        </DataLoader>
    );
};
