import React from 'react';
import PropTypes from 'prop-types';
import { adopt } from 'react-adopt';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import get from 'lodash/get';
import storage from 'web-storage';
import qs from 'query-string';

import { NotFoundError } from 'routing/errors';
import ApplicationPage from 'site/application-page';
import { GlobalConsumer } from 'site/context';
import Redirect from 'routing/redirect';
import CampaignPage from './campaign-page';

const { localStorage } = storage();

const CampaignPageFragment = gql`
    fragment CampaignPageData on Campaign {
        defaultFundraiserMessage
        id
        slug
        theme
        themeOverrides
        matchId
        enableFundraisers
        status
        sponsor {
            displayInStory
        }
    }
`;

const GET_DRAFT_CAMPAIGN = gql`
    ${CampaignPageFragment}
    query GetCampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            ...CampaignPageData
        }
    }
`;

const GET_LIVE_CAMPAIGN = gql`
    ${CampaignPageFragment}
    query GetCampaignBySlug($slug: String!) {
        campaign: campaignBySlug(slug: $slug) {
            ...CampaignPageData
        }
    }
`;

const GET_ROLES = gql`
    query GetCampaignRoles($id: ID!) {
        my {
            roles: rolesFor(type: "campaign", id: $id)
            user {
                id
                username
            }
        }
    }
`;

/* eslint-disable react/prop-types */
const Wrapper = adopt(
    {
        global: <GlobalConsumer />,
        campaignQuery: ({ slug, id, render }) => (
            <Query query={slug ? GET_LIVE_CAMPAIGN : GET_DRAFT_CAMPAIGN} variables={{ slug, id }}>
                {render}
            </Query>
        ),
        roleQuery: ({ campaignQuery, global, render }) => {
            const id = get(campaignQuery, 'data.campaign.id');
            return (
                <Query skip={!id || !global.user} query={GET_ROLES} variables={{ id }}>
                    {render}
                </Query>
            );
        },
    },
    ({ global, campaignQuery, roleQuery }) => ({
        global,
        error: campaignQuery.error || roleQuery.error,
        loading: campaignQuery.loading,
        campaign: get(campaignQuery, 'data.campaign'),
        roles: get(roleQuery, 'data.my.roles', []),
    })
);
/* eslint-enable react/prop-types */

const CampaignPageContainer = ({ match, location, isFundraisingOnLoad }) => (
    <ApplicationPage header="minimal">
        <Wrapper slug={match.params.slug} id={match.params.id}>
            {({ global, error, loading, campaign, roles }) => {
                if (error) throw error;
                if (loading) return null;

                // This is our wildcard route, so anything that doesn't
                // return a campaign needs to return a 404.
                // The GraphQL API doesn't check against status so we
                // also need to ensure the campaign hasn't been archived.
                if (!campaign || campaign.status === 'archived') {
                    throw new NotFoundError('Campaign not found');
                }

                // Accessed a live campaign via a preview URL
                if (match.params.id && campaign.status === 'published') {
                    return <Redirect to={`/${campaign.slug}`} status={301} />;
                }

                let { fundraiser } = match.params;
                if (!fundraiser && roles.includes('fundraiser')) {
                    fundraiser = global.user.username;
                }

                // If there was a tracking code query param, store it for
                // future contributions
                const queryParams = qs.parse(location.search);
                if (fundraiser && queryParams.trk) {
                    localStorage.set(`referral.${campaign.id}.${fundraiser}`, queryParams.trk);
                }

                const recentlyContributed =
                    global.recentContribution && global.recentContribution.campaign === campaign.id;

                return (
                    <CampaignPage
                        isFundraisingOnLoad={isFundraisingOnLoad && !roles.includes('fundraiser')}
                        campaign={campaign}
                        roles={roles}
                        user={global.user}
                        fundraiser={fundraiser}
                        recentContribution={recentlyContributed ? global.recentContribution : null}
                        clearRecentContribution={() => global.setRecentContribution(null)}
                        setReferrer={referrer =>
                            global.lockContributionReferrer(campaign.id, referrer)
                        }
                    />
                );
            }}
        </Wrapper>
    </ApplicationPage>
);

CampaignPageContainer.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
            slug: PropTypes.string,
            fundraiser: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,
    isFundraisingOnLoad: PropTypes.bool,
};

CampaignPageContainer.defaultProps = {
    isFundraisingOnLoad: false,
};

export default CampaignPageContainer;
