import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import storage from 'web-storage';

import GlobalContext from 'site/context';
import OAuthSignIn from 'pages/sign-in/oauth';
import Link from 'common/link';
import Button from 'common/button';
import Icon from 'common/icon';

const { localStorage } = storage();

const FundraiseRegister = ({ onSignIn, onRegister }) => {
    const { register, showFlash } = useContext(GlobalContext);

    return (
        <>
            <h1 className="text-lg font-bold leading-tight my-4 text-gray-dark">
                We just need a little information:
            </h1>
            <OAuthSignIn className="my-8" onSuccess={onRegister} />

            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    username: '',
                    phone: '',
                }}
                validationSchema={yup.object().shape({
                    firstName: yup.string().required('First name is required'),
                    lastName: yup.string().required('Last name is required'),
                    email: yup
                        .string()
                        .email('Invalid email')
                        .required('Email is required'),
                    username: yup
                        .string()
                        .required('Username is required')
                        .matches(/^[a-zA-Z0-9\-_]+$/, {
                            message: 'Letters and numbers only with no spaces',
                        }),
                    phone: yup.string().phone('Invalid phone number'),
                })}
                onSubmit={async (values, { setSubmitting, setFieldError }) => {
                    try {
                        localStorage.set('authUser.email', values.email);
                        await register(values);
                        setSubmitting(false);
                        onRegister();
                    } catch (err) {
                        if (err.message.includes('User already exists')) {
                            showFlash({
                                type: 'info',
                                title: 'It looks like you already have an account!',
                                message:
                                    'Please sign in or [reset your password](/forgot-password)',
                            });

                            setFieldError('email', 'User already exists with this email');
                            setSubmitting(false);
                            return;
                        }

                        showFlash({
                            type: 'danger',
                            title: 'Woops!',
                            message:
                                'Something went wrong when trying to register your account. Please try again.',
                            duration: 5000,
                        });
                        setSubmitting(false);
                    }
                }}
            >
                {({ isSubmitting, touched, errors }) => (
                    <Form className="-mt-2">
                        <div className="mt-2">
                            <Field
                                name="firstName"
                                placeholder="First name"
                                className={cx('input-default w-full', {
                                    'is-invalid': touched.firstName && errors.firstName,
                                })}
                            />
                            <ErrorMessage
                                component="p"
                                className="text-red mt-2 text-12"
                                name="firstName"
                            />
                        </div>
                        <div className="mt-2">
                            <Field
                                name="lastName"
                                placeholder="Last name"
                                className={cx('input-default w-full', {
                                    'is-invalid': touched.lastName && errors.lastName,
                                })}
                            />
                            <ErrorMessage
                                component="p"
                                className="text-red mt-2 text-12"
                                name="lastName"
                            />
                        </div>
                        <div className="mt-2">
                            <Field
                                type="email"
                                name="email"
                                placeholder="Email"
                                className={cx('input-default w-full', {
                                    'is-invalid': touched.email && errors.email,
                                })}
                            />
                            <ErrorMessage
                                component="p"
                                className="text-red mt-2 text-12"
                                name="email"
                            />
                        </div>
                        <div className="mt-2">
                            <Field
                                type="username"
                                name="username"
                                placeholder="Username (Used for your URL)"
                                className={cx('input-default w-full', {
                                    'is-invalid': touched.username && errors.username,
                                })}
                            />
                            <ErrorMessage
                                component="p"
                                className="text-red mt-2 text-12"
                                name="username"
                            />
                        </div>
                        <div className="mt-2">
                            <Field
                                type="phone"
                                name="phone"
                                placeholder="Phone (Optional)"
                                className={cx('input-default w-full', {
                                    'is-invalid': touched.phone && errors.phone,
                                })}
                            />
                            <ErrorMessage
                                component="p"
                                className="text-red mt-2 text-12"
                                name="phone"
                            />
                        </div>
                        <div className="mt-8 text-12 font-medium text-gray-70">
                            <p>
                                By registering, you agree to our{' '}
                                <Link to="/tos" className="link-green" external>
                                    Terms of Service.
                                </Link>
                            </p>
                            <p className="mt-2">
                                Already have an account?{' '}
                                <button type="button" className="link-green" onClick={onSignIn}>
                                    Sign in here.
                                </button>
                            </p>
                        </div>

                        <div className="mt-8">
                            <Button
                                type="submit"
                                color="green"
                                size="md"
                                className={cx('text-14 font-medium block w-full', {
                                    'pointer-events-none opacity-60': isSubmitting,
                                })}
                            >
                                {isSubmitting ? (
                                    <Icon icon="spinner" size="lg" className="leading-none" spin />
                                ) : (
                                    'Submit my information'
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

FundraiseRegister.propTypes = {
    onSignIn: PropTypes.func.isRequired,
    onRegister: PropTypes.func.isRequired,
};

export default FundraiseRegister;
