import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

import CampaignContent from './campaign-content';

import CampaignPageContext from '../context';

const GET_CONTENT = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            name
            fullDescription
        }
    }
`;

const CampaignContentContainer = () => {
    const { id, theme } = useContext(CampaignPageContext);

    return (
        <Query query={GET_CONTENT} variables={{ id }}>
            {({ error, loading, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { campaign } = data;

                return <CampaignContent campaign={campaign} theme={theme} />;
            }}
        </Query>
    );
};

export default CampaignContentContainer;
