import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

import ManagerActions from './manager-actions';

import CampaignPageContext from '../context';

const GET_CAMPAIGN = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            status
        }
    }
`;

const ManagerActionsContainer = () => {
    const { id } = useContext(CampaignPageContext);
    return (
        <Query query={GET_CAMPAIGN} variables={{ id }}>
            {({ error, loading, data }) => {
                if (error) throw error;
                if (loading) return null;

                const isPublished = data.campaign.status === 'published';

                const action = {
                    label: isPublished ? 'View Campaign Dashboard' : 'Edit Your Campaign',
                    href: isPublished
                        ? `/manage/campaigns/${id}/report`
                        : `/manage/campaigns/${id}/edit`,
                };

                return <ManagerActions action={action} />;
            }}
        </Query>
    );
};

export default ManagerActionsContainer;
