import React, { Component } from 'react';
import PropTypes from 'prop-types';

import config from 'config';
import { setCookie, clearCookie } from 'utils';
import { FundraiseProvider } from './context';
import ProgressBar from './progress-bar';
import Intro from './intro';
import EditMessage from './edit-message';
import Register from './register';
import SignIn from './sign-in';
import UserDetails from './user-details';
import Confirm from './confirm';
import Referrals from './referrals';
import Thanks from './thanks';
import ManualRefer from './manual-refer';
import GoogleRefer from './google-refer';

class FundraisePage extends Component {
    static propTypes = {
        user: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            email: PropTypes.string,
            username: PropTypes.string,
            avatar: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
        fundraiser: PropTypes.shape({
            id: PropTypes.string,
        }),
        campaign: PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        }).isRequired,
        updateUser: PropTypes.func.isRequired,
        createFundraiser: PropTypes.func.isRequired,
        updateFundraiser: PropTypes.func.isRequired,
        createReferral: PropTypes.func.isRequired,
        signOut: PropTypes.func.isRequired,
        showFlash: PropTypes.func.isRequired,
        onComplete: PropTypes.func.isRequired,
    };

    static defaultProps = {
        user: null,
        fundraiser: null,
    };

    state = {
        activePage: 'intro',
        message: null,
    };

    componentDidMount() {
        const { campaign } = this.props;
        setCookie('contexturl', `${config('/hostname')}/${campaign.slug}/fundraise`);
    }

    setActivePage = page => this.setState({ activePage: page });

    gotoNextAuthPage = () => {
        const { user } = this.props;

        // Need to sign-in/register
        if (!user) {
            this.setActivePage('register');
            return;
        }

        // Needs some required info
        if (!user.firstName || !user.lastName || !user.email || !user.username) {
            this.setActivePage('userDetails');
            return;
        }

        // Ready to confirm fundraiser
        this.setActivePage('confirm');
    };

    handleUpdateMessage = async ({ message }, { setSubmitting }) => {
        const { fundraiser, updateFundraiser, showFlash } = this.props;

        // User has already created the fundraiser record and is
        // updating their message as part of the referral entry
        if (fundraiser) {
            try {
                await updateFundraiser({ message });
                setSubmitting(false);
                this.setActivePage('refer');
                return;
            } catch (err) {
                setSubmitting(false);
                showFlash({
                    type: 'danger',
                    title: 'Woops!',
                    message: 'We were unable to save your message. Please try again',
                    duration: 5000,
                });
            }
        }

        this.setState({ message });
        this.gotoNextAuthPage();
    };

    handleChangeUser = () => {
        const { signOut } = this.props;
        signOut();
        this.setActivePage('signIn');
    };

    handleUpdateUser = async (values, { setSubmitting }) => {
        const { updateUser, showFlash } = this.props;

        try {
            await updateUser(values);
            setSubmitting(false);
            this.gotoNextAuthPage();
        } catch (err) {
            showFlash({
                type: 'danger',
                title: 'Woops!',
                message: 'We were unable to save your changes. Please try again.',
                duration: 5000,
            });
            setSubmitting(false);
        }
    };

    handleCreateFundraiser = async ({ setSubmitting }) => {
        const { createFundraiser, showFlash } = this.props;
        const { message } = this.state;

        try {
            setSubmitting(true);
            await createFundraiser({ message });
            setSubmitting(false);
            clearCookie('contexturl');
            this.setActivePage('refer');
        } catch (err) {
            setSubmitting(false);
            showFlash({
                type: 'danger',
                title: 'Woops!',
                message: 'We were unable to sign you up at this time. Please try again.',
                duration: 3000,
            });
        }
    };

    handleCreateReferrals = async (referrals, { setSubmitting }) => {
        const { showFlash, createReferral } = this.props;
        const wordSmith = referrals.length === 1 ? 'referral' : 'referrals';

        if (referrals.length < 1) {
            showFlash({
                type: 'danger',
                title: 'No contacts added!',
                message: 'You need to add at least one contact.',
                duration: 3000,
            });

            setSubmitting(false);
            return;
        }

        try {
            /* Let's loop through all the create referrals and call them */
            // eslint-disable-next-line no-unused-vars
            for (const referral of referrals) {
                await createReferral(referral);
            }

            setSubmitting(false);

            showFlash({
                type: 'info',
                title: 'Referrals Sent!',
                message: `You sent ${referrals.length} ${wordSmith}.`,
                duration: 3000,
            });

            this.setActivePage('thanks');
        } catch (err) {
            showFlash({
                type: 'danger',
                title: 'Error sending messages',
                message: `Sorry, there was an error sending your referrals.`,
                duration: 5000,
            });

            setSubmitting(false);
        }
    };

    render() {
        const { campaign, fundraiser, onComplete } = this.props;
        const { activePage } = this.state;

        return (
            <FundraiseProvider campaign={campaign} fundraiser={fundraiser} activePage={activePage}>
                <div className="p-8 text-center">
                    <ProgressBar onChange={this.setActivePage} />
                    <div className="min-h-48">
                        {(() => {
                            switch (activePage) {
                                case 'intro':
                                    return (
                                        <Intro onContinue={() => this.setActivePage('message')} />
                                    );

                                case 'message':
                                    return <EditMessage onSubmit={this.handleUpdateMessage} />;

                                case 'register':
                                    return (
                                        <Register
                                            onSignIn={() => this.setActivePage('signIn')}
                                            onRegister={this.gotoNextAuthPage}
                                        />
                                    );

                                case 'signIn':
                                    return (
                                        <SignIn
                                            onSignIn={this.gotoNextAuthPage}
                                            onRegister={() => this.setActivePage('register')}
                                        />
                                    );

                                case 'userDetails':
                                    return (
                                        <UserDetails
                                            onChangeUser={this.handleChangeUser}
                                            onSubmit={this.handleUpdateUser}
                                        />
                                    );

                                case 'confirm':
                                    return (
                                        <Confirm
                                            onChangeUser={this.handleChangeUser}
                                            onSubmit={this.handleCreateFundraiser}
                                        />
                                    );

                                case 'refer':
                                    return (
                                        <Referrals
                                            onEditMessage={() => this.setActivePage('message')}
                                            onImportContacts={() =>
                                                this.setActivePage('googleRefer')
                                            }
                                            onEnterContacts={() =>
                                                this.setActivePage('manualRefer')
                                            }
                                            onSkip={() => this.setActivePage('thanks')}
                                        />
                                    );

                                case 'manualRefer':
                                    return (
                                        <ManualRefer
                                            onSubmit={this.handleCreateReferrals}
                                            onCancel={() => this.setActivePage('refer')}
                                        />
                                    );

                                case 'googleRefer':
                                    return (
                                        <GoogleRefer
                                            onSubmit={this.handleCreateReferrals}
                                            onCancel={() => this.setActivePage('refer')}
                                        />
                                    );

                                case 'thanks':
                                    return <Thanks onComplete={onComplete} />;

                                default:
                                    return null;
                            }
                        })()}
                    </div>
                </div>
            </FundraiseProvider>
        );
    }
}

export default FundraisePage;
