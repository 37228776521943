import React, { useContext } from 'react';
import { adopt } from 'react-adopt';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import get from 'lodash/get';
import config from 'config';

import CampaignPageContext from '../context';
import CampaignShare from './campaign-share';

const GET_SHARE = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            slug
            name
            defaultShareMessage
        }
    }
`;

/* eslint-disable */
const DataLoader = adopt(
    {
        campaignQuery: ({ id, render }) => (
            <Query query={GET_SHARE} variables={{ id }}>
                {render}
            </Query>
        ),
    },
    ({ campaignQuery }) => ({
        error: campaignQuery.error,
        loading: campaignQuery.loading,
        campaign: get(campaignQuery, 'data.campaign'),
    })
);
/* eslint-enable */

const CampaignShareContainer = () => {
    const { id, slug } = useContext(CampaignPageContext);
    return (
        <DataLoader id={id}>
            {({ error, loading, campaign }) => {
                if (error) throw error;
                if (loading) return null;

                return (
                    <CampaignShare
                        url={`${config('/hostname')}/${slug}`}
                        message={campaign.defaultShareMessage}
                    />
                );
            }}
        </DataLoader>
    );
};

export default CampaignShareContainer;
