import React, { useContext } from 'react';
import get from 'lodash/get';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

import CampaignRecipient from './campaign-recipient';

import CampaignPageContext from '../context';

const GET_RECIPIENT = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            recipient {
                id
                type
                name
                description
                url
                logo {
                    id
                }
                isVerified
                isTaxDeductible
            }
        }
    }
`;

const CampaignRecipientContainer = () => {
    const { id, theme } = useContext(CampaignPageContext);

    return (
        <Query query={GET_RECIPIENT} variables={{ id }}>
            {({ error, loading, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { campaign } = data;
                const { recipient } = campaign;
                const hasLogo = !!get(recipient, 'logo.id');

                if (!recipient || recipient.type === 'individual') return null;

                return <CampaignRecipient hasLogo={hasLogo} recipient={recipient} theme={theme} />;
            }}
        </Query>
    );
};

export default CampaignRecipientContainer;
