import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/button';
import Link from 'common/link';

const CampaignContributorActions = ({ action, formattedDate, theme, url }) => (
    <Button
        component={Link}
        className="rounded-none lg:rounded-t-lg w-full font-medium fixed pin-b pin-x z-40 lg:relative"
        css={{
            background: theme.colors.buttonBackground,
            color: theme.colors.buttonText,
            '&:hover': {
                background: theme.colors.buttonHoverBackground,
                color: theme.colors.buttonHoverText,
            },
            ...theme.overrides.pledgeButton,
        }}
        size="md"
        to={url}
        legacy
    >
        You {action} to this campaign on <span className="whitespace-no-wrap">{formattedDate}</span>
        <span className="block uppercase font-bold text-2xs mt-2 text-black-30">
            Manage your contribution
        </span>
    </Button>
);

CampaignContributorActions.propTypes = {
    action: PropTypes.string.isRequired,
    formattedDate: PropTypes.string.isRequired,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            buttonBackground: PropTypes.string,
            buttonText: PropTypes.string,
            buttonHoverBackground: PropTypes.string,
            buttonHoverText: PropTypes.string,
        }),
        overrides: PropTypes.object,
    }).isRequired,
    url: PropTypes.string.isRequired,
};

export default CampaignContributorActions;
