import React from 'react';
import PropTypes from 'prop-types';
import { adopt } from 'react-adopt';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Login } from 'react-facebook';

import { GoogleConsumer } from 'data/services/google';
import GET_AUTH_USER from 'data/queries/get-auth-user';
import OAuthSignIn from './sign-in-oauth';

const OAUTH = gql`
    mutation OAuth($service: OAuthService!, $token: String!) {
        oauth(service: $service, token: $token)
    }
`;

/* eslint-disable react/prop-types, react/destructuring-assignment */
const Wrapper = adopt({
    oauth: ({ render }) => (
        <Mutation mutation={OAUTH} refetchQueries={[{ query: GET_AUTH_USER }]} awaitRefetchQueries>
            {mutate => render(variables => mutate({ variables }))}
        </Mutation>
    ),
    google: <GoogleConsumer />,
    facebookLogin: ({ oauth, onSuccess, render }) => (
        <Login
            scope="email"
            onCompleted={async ({ tokenDetail }) => {
                await oauth({ service: 'facebook', token: tokenDetail.accessToken });
                onSuccess();
            }}
        >
            {render}
        </Login>
    ),
});
/* eslint-enable react/prop-types, react/destructuring-assignment */

const SignInOAuthContainer = ({ onSuccess, ...props }) => (
    <Wrapper onSuccess={onSuccess}>
        {({ oauth, google, facebookLogin }) => (
            <OAuthSignIn
                onFacebook={facebookLogin.handleClick}
                onGoogle={async () => {
                    await google.ensureScope('email profile');
                    await oauth({
                        service: 'google',
                        token: google.currentUser.getAuthResponse(true).access_token,
                    });
                    onSuccess();
                }}
                {...props}
            />
        )}
    </Wrapper>
);

SignInOAuthContainer.propTypes = {
    onSuccess: PropTypes.func.isRequired,
};

export default SignInOAuthContainer;
