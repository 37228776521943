import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { formatCurrency } from 'utils';
import { adopt } from 'react-adopt';
import Redirect from 'routing/redirect';

import useIsMounted from 'hooks/use-is-mounted';
import GlobalContext from 'site/context';
import CampaignContext from '../context';
import CampaignFundraiser from './campaign-fundraiser';

const GET_FUNDRAISER_LIST = gql`
    query FundraiserListForCampaign($campaignId: ID!) {
        fundraiserList: fundraisersByCampaign(
            campaignId: $campaignId
            sort: "-stats.amountRaisedEstimate"
            limit: 0
        ) {
            fundraisers: items {
                id
            }
        }
    }
`;

const GET_FUNDRAISER = gql`
    query FundraiserByUsername($username: String!, $campaignId: ID!) {
        fundraiser: fundraiserByUsername(username: $username, campaignId: $campaignId) {
            id
            user {
                id
                firstName
                lastName
                avatar {
                    id
                }
            }
            message
            stats {
                committedUsers
                amountRaisedEstimate
            }
            referrals {
                createdAt
            }
        }
    }
`;

/* eslint-disable */
const CampaignFundraiserWrapper = adopt(
    {
        fundraiserListQuery: ({ id, render }) => (
            <Query query={GET_FUNDRAISER_LIST} variables={{ campaignId: id }}>
                {render}
            </Query>
        ),
        fundraiserQuery: ({ username, id, render }) => (
            <Query query={GET_FUNDRAISER} variables={{ username, campaignId: id }}>
                {render}
            </Query>
        ),
    },
    ({ context, fundraiserListQuery, fundraiserQuery }) => ({
        error: fundraiserListQuery.error || fundraiserQuery.error,
        isLoading: fundraiserListQuery.loading || fundraiserQuery.loading,
        fundraisers: get(fundraiserListQuery, 'data.fundraiserList.fundraisers'),
        fundraiser: get(fundraiserQuery, 'data.fundraiser'),
    })
);
/* eslint-enable */

const CampaignFundraiserContainer = ({ fundraiser: username }) => {
    const isMounted = useIsMounted();
    const { showFlash } = useContext(GlobalContext);
    const { id, slug, theme } = useContext(CampaignContext);

    return (
        <CampaignFundraiserWrapper username={username} id={id}>
            {({ error, isLoading, fundraisers, fundraiser }) => {
                if (error) throw error;
                if (isLoading) return null;

                if (!fundraiser) {
                    // The flash message won't appear if we redirect on the server
                    // so we let the client always handle the redirection
                    if (!isMounted) return null;

                    showFlash({
                        type: 'info',
                        title: 'Fundraiser not found',
                        message: 'Please check the leaderboard to find their page',
                        duration: 7000,
                        iconName: 'user-times',
                        iconStyle: 'solid',
                    });

                    return <Redirect to={`/${slug}`} />;
                }

                const amountRaised = get(fundraiser, 'stats.amountRaisedEstimate', 0);
                const formattedAmountRaised = formatCurrency(amountRaised);
                const referralCount = get(fundraiser, 'stats.committedUsers', 0);
                const supporterTerm = referralCount === 1 ? 'supporter' : 'supporters';
                const rank = findIndex(fundraisers, x => x.id === fundraiser.id) + 1;
                const isRanked = !!amountRaised;
                const fundraiserCount = fundraisers.length;

                return (
                    <CampaignFundraiser
                        formattedAmountRaised={formattedAmountRaised.toString()}
                        fundraiser={fundraiser}
                        fundraiserCount={fundraiserCount}
                        isRanked={isRanked}
                        rank={rank}
                        referralCount={referralCount}
                        supporterTerm={supporterTerm}
                        theme={theme}
                    />
                );
            }}
        </CampaignFundraiserWrapper>
    );
};

CampaignFundraiserContainer.propTypes = {
    fundraiser: PropTypes.string.isRequired,
};

export default CampaignFundraiserContainer;
