import React, { useState } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import useDidMount from '@rooks/use-did-mount';

export default PassedComponent => {
    const NewComponent = props => {
        const [didMount, setDidMount] = useState(false);
        useDidMount(() => setDidMount(true));
        return didMount ? <PassedComponent {...props} /> : null;
    };

    NewComponent.displayName = `browserOnly(${PassedComponent.displayName ||
        PassedComponent.name})`;
    NewComponent.WrappedComponent = PassedComponent;

    return hoistStatics(NewComponent, PassedComponent);
};
