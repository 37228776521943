import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import storage from 'web-storage';

import OAuthSignIn from 'pages/sign-in/oauth';
import GlobalContext from 'site/context';
import Button from 'common/button';
import Link from 'common/link';
import Icon from 'common/icon';

const { localStorage } = storage();

const FundraiserSignIn = ({ onSignIn, onRegister }) => {
    const { signIn, showFlash } = useContext(GlobalContext);

    return (
        <>
            <h1 className="text-lg font-bold leading-tight my-4 text-gray-dark">Sign In</h1>
            <OAuthSignIn className="my-8" onSuccess={onSignIn} />
            <Formik
                initialValues={{
                    email: localStorage.get('authUser.email') || '',
                    password: '',
                }}
                validationSchema={yup.object().shape({
                    email: yup
                        .string()
                        .email('Invalid email')
                        .required('Email is required'),
                    password: yup
                        .string()
                        .min(6, 'Must be at least 6 characters')
                        .required('Password is required'),
                })}
                onSubmit={async (values, { setSubmitting, setFieldError }) => {
                    try {
                        localStorage.set('authUser.email', values.email);
                        await signIn(values);
                        setSubmitting(false);
                        onSignIn();
                    } catch (err) {
                        if (err.message.includes('No user found')) {
                            setFieldError('email', 'Email not found');
                            setSubmitting(false);

                            showFlash({
                                type: 'danger',
                                title: 'Are you sure?',
                                message: `No users found with the email address "${values.email}"`,
                                duration: 5000,
                            });

                            return;
                        }

                        if (err.message.includes('Incorrect password')) {
                            setFieldError('password', 'Incorrect password');
                            setSubmitting(false);

                            showFlash({
                                type: 'danger',
                                title: 'Are you sure?',
                                message:
                                    'The password you provided does not match our records. Consider [resetting your password](/forgot-password)',
                                duration: 5000,
                            });

                            return;
                        }

                        setSubmitting(false);
                        showFlash({
                            type: 'danger',
                            title: 'Woops!',
                            message:
                                'Something went wrong when trying to sign you in. Please try again.',
                            duration: 5000,
                        });
                    }
                }}
            >
                {({ isSubmitting, touched, errors }) => (
                    <Form className="-mt-2">
                        <div className="mt-2">
                            <Field
                                type="email"
                                name="email"
                                placeholder="Email"
                                className={cx('input-default w-full', {
                                    'is-invalid': touched.email && errors.email,
                                })}
                            />
                            <ErrorMessage
                                component="p"
                                className="text-red mt-2 text-12"
                                name="email"
                            />
                        </div>
                        <div className="mt-2">
                            <Field
                                type="password"
                                name="password"
                                placeholder="Password"
                                className={cx('input-default w-full', {
                                    'is-invalid': touched.password && errors.password,
                                })}
                            />
                            <ErrorMessage
                                component="p"
                                className="text-red mt-2 text-12"
                                name="password"
                            />
                        </div>

                        <div className="mt-8 text-12 font-medium text-gray-70">
                            <p>
                                <Link to="/forgot-password" className="link-green">
                                    Forgot your password?
                                </Link>
                            </p>
                            <p className="mt-2">
                                Don&apos;t have an account?{' '}
                                <button type="button" className="link-green" onClick={onRegister}>
                                    Create one.
                                </button>
                            </p>
                        </div>

                        <div className="mt-8">
                            <Button
                                type="submit"
                                color="green"
                                size="md"
                                className={cx('text-14 font-medium block w-full', {
                                    'pointer-events-none opacity-60': isSubmitting,
                                })}
                            >
                                {isSubmitting ? (
                                    <Icon icon="spinner" size="lg" className="leading-none" spin />
                                ) : (
                                    'Sign In'
                                )}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

FundraiserSignIn.propTypes = {
    onRegister: PropTypes.func.isRequired,
    onSignIn: PropTypes.func.isRequired,
};

export default FundraiserSignIn;
