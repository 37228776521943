import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SkewText from 'common/skew-text';
import Modal from 'common/modal';
import SmallScreen from 'common/small-screen';
import LargeScreen from 'common/large-screen';
import Divider from 'common/divider';
import Button from 'common/button';
import Collapsible from 'common/collapsible';
import JoinAsFundraiser from 'pages/fundraise';
import CampaignPageContext from '../context';
import FundraiserPlacementCard from './item-placement';
import FundraiserTopCard from './item-top';

const CampaignFundraisers = ({ campaign, fundraisers, shouldDisplayCTA }) => {
    const { isDraft, theme, isFundraisingOnLoad } = useContext(CampaignPageContext);
    const [shouldShowJoinModal, setShouldShowJoinModal] = useState(isFundraisingOnLoad);

    const topTen = fundraisers.slice(0, 10);
    const topThree = topTen.slice(0, 3);
    const hasMoreThanThree = fundraisers.length > 3;
    const hasMoreThanTen = fundraisers.length > 10;

    return (
        <Collapsible>
            {({ isExpanded, toggle }) => (
                <div className="my-20">
                    <SkewText
                        component="h2"
                        backgroundColor={theme.colors.titleBackground}
                        textColor={theme.colors.titleText}
                        className="font-display uppercase font-regular leading-tight text-center mt-20"
                        css={{ fontSize: '5rem' }}
                    >
                        Fundraiser Leaderboard
                    </SkewText>
                    <LargeScreen>
                        <div className="mt-4">
                            <div className="flex justify-center -mt-8 -ml-8">
                                {topThree.map(fundraiser => (
                                    <div className="pl-8 pt-8 w-1/3" key={fundraiser.url}>
                                        <FundraiserPlacementCard
                                            rank={fundraiser.rank}
                                            url={fundraiser.url}
                                            firstName={fundraiser.user.firstName}
                                            lastName={fundraiser.user.lastName}
                                            avatar={fundraiser.user.avatar.id}
                                            statLabel={
                                                campaign.isComplete ? 'Total raised' : 'Est raised'
                                            }
                                            statValue={fundraiser.formattedAmountRaised}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        {hasMoreThanThree && (
                            <div className="mt-8">
                                <div
                                    className="rounded shadow-lg"
                                    css={{
                                        background: theme.colors.panelBackground,
                                    }}
                                >
                                    {(isExpanded ? fundraisers : topTen)
                                        .slice(3)
                                        .map((fundraiser, i) => (
                                            <FundraiserTopCard
                                                key={fundraiser.url}
                                                url={fundraiser.url}
                                                bordered={i > 0}
                                                rank={fundraiser.rank}
                                                firstName={fundraiser.user.firstName}
                                                lastName={fundraiser.user.lastName}
                                                avatar={fundraiser.user.avatar.id}
                                                statValue={fundraiser.formattedAmountRaised}
                                            />
                                        ))}
                                </div>
                            </div>
                        )}
                    </LargeScreen>
                    <SmallScreen>
                        <div className="mt-8">
                            <div
                                className="rounded shadow-lg"
                                css={{
                                    background: theme.colors.panelBackground,
                                }}
                            >
                                {(isExpanded ? fundraisers : topTen).map((fundraiser, i) => (
                                    <FundraiserTopCard
                                        key={fundraiser.user.username}
                                        url={fundraiser.url}
                                        bordered={!!i}
                                        rank={fundraiser.rank}
                                        firstName={fundraiser.user.firstName}
                                        lastName={fundraiser.user.lastName}
                                        avatar={fundraiser.user.avatar.id}
                                        statValue={fundraiser.formattedAmountRaised}
                                    />
                                ))}
                            </div>
                        </div>
                    </SmallScreen>

                    {hasMoreThanTen && (
                        <Divider color={theme.colors.border} className="mt-12">
                            <Button
                                onClick={toggle}
                                className="uppercase text-2xs font-bold tracking-wide"
                                css={{
                                    color: theme.colors.link,
                                    '&:hover': {
                                        color: theme.colors.linkHover,
                                    },
                                }}
                            >
                                {isExpanded
                                    ? 'Collapse'
                                    : `View all ${fundraisers.length} fundraisers`}
                            </Button>
                        </Divider>
                    )}

                    {shouldDisplayCTA && (
                        <div
                            className={cx(
                                'text-sm text-center leading-loose mt-12 md:max-w-2/3 mx-auto',
                                { 'opacity-25 pointer-events-none': isDraft }
                            )}
                            css={{ color: theme.colors.text }}
                        >
                            <p className="mb-8">
                                Help this campaign reach its goal! Create a personal fundraising
                                profile to raise money for this campaign.
                            </p>
                            <Button
                                size="md"
                                className="font-medium"
                                css={{
                                    background: theme.colors.buttonBackground,
                                    color: theme.colors.buttonText,
                                    '&:hover': {
                                        background: theme.colors.buttonHoverBackground,
                                        color: theme.colors.buttonHoverText,
                                    },
                                }}
                                onClick={() => setShouldShowJoinModal(true)}
                            >
                                Become a fundraiser
                            </Button>
                        </div>
                    )}

                    <Modal close={() => setShouldShowJoinModal(false)} isOpen={shouldShowJoinModal}>
                        <JoinAsFundraiser
                            campaign={campaign.id}
                            onComplete={() => setShouldShowJoinModal(false)}
                        />
                    </Modal>
                </div>
            )}
        </Collapsible>
    );
};

CampaignFundraisers.propTypes = {
    campaign: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        isComplete: PropTypes.bool.isRequired,
        enableFundraisers: PropTypes.bool.isRequired,
    }).isRequired,
    fundraisers: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            rank: PropTypes.number,
            user: PropTypes.shape({
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                avatar: PropTypes.shape({
                    id: PropTypes.string.isRequired,
                }).isRequired,
            }),
            formattedAmountRaised: PropTypes.string.isRequired,
        })
    ).isRequired,
    shouldDisplayCTA: PropTypes.bool.isRequired,
};

export default CampaignFundraisers;
