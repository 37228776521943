import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';

import Link from 'common/link';

const CampaignSidebarSponsor = ({ logo, sponsor, theme }) => (
    <div className="mt-8 flex justify-center items-center -ml-4">
        <div className="pl-4">
            <p
                className="text-2xs uppercase font-bold tracking-wide"
                css={{ color: theme.colors.panelTextMuted }}
            >
                Proudly supported by
            </p>
        </div>
        <div className="pl-4">
            {sponsor.url ? (
                <Link to={sponsor.url} external>
                    <Image
                        publicId={logo}
                        alt={sponsor.name}
                        transformation="logo"
                        className="inline-block align-middle max-w-36 max-h-12"
                    />
                </Link>
            ) : (
                <Image
                    publicId={logo}
                    alt={sponsor.name}
                    transformation="logo"
                    className="inline-block align-middle max-w-36 max-h-12"
                />
            )}
        </div>
    </div>
);

CampaignSidebarSponsor.propTypes = {
    logo: PropTypes.string.isRequired,
    sponsor: PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
    }).isRequired,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            panelTextMuted: PropTypes.string,
        }),
    }).isRequired,
};

export default CampaignSidebarSponsor;
