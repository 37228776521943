import React, { Component } from 'react';
import PropTypes from 'prop-types';
import without from 'lodash/without';
import cx from 'classnames';

import Button from 'common/button';
import Icon from 'common/icon';
import Search from './search';
import List from './list';

class GoogleImport extends Component {
    static propTypes = {
        onSearch: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    state = {
        query: '',
        isShowingResults: false,
        selectedContacts: [],
        importOptionsList: [],
        isShowingActive: false,
        isSubmitting: false,
    };

    /**
     * Is this user email in the array of selected items
     *
     * @param {string} email The email of the individual
     *
     * @returns
     *  boolean, true if the email exists, false if it does not
     *
     */
    activeCheck = email => {
        const { selectedContacts } = this.state;
        return selectedContacts.some(x => x.email === email);
    };

    /**
     * User only wants to see the active items
     *
     * @fires
     *  this.setState on isShowingActive, so that the system shows only active items
     *
     */
    onShowSelected = () => {
        this.setState(() => ({
            isShowingActive: true,
        }));
    };

    /**
     * Toggles an item to the active state or not.
     *
     * @param {object} contact The individual contact information
     *
     * @fires
     *  this.setState on selectedContacts, updating it with the appropriate new list of items
     *  and isShowingActive to false, so that the system for reviewing active only resets itself
     *
     */

    onClickContact = contact => {
        const { selectedContacts } = this.state;
        const existing = selectedContacts.find(x => x.email === contact.email);

        // If there are no matches in the comparison filter, add it to the array of selected items
        // If there is a match, delete it from the array of selected items
        this.setState({
            selectedContacts: existing
                ? without(selectedContacts, existing)
                : [...selectedContacts, contact],
        });
    };

    handleSearch = async query => {
        const { onSearch } = this.props;

        this.setState({ query });

        if (query.length < 3) {
            this.setState({ isShowingResults: false });
            return;
        }

        this.setState({ isShowingResults: true, isShowingActive: false });

        const results = await onSearch(query);
        this.setState(() => ({ importOptionsList: results }));
    };

    handleSubmit = async () => {
        const { onSubmit } = this.props;
        const { selectedContacts } = this.state;
        onSubmit(selectedContacts, { setSubmitting: x => this.setState({ isSubmitting: x }) });
    };

    render() {
        const { onCancel } = this.props;

        const {
            query,
            isShowingResults,
            importOptionsList,
            selectedContacts,
            isShowingActive,
            isSubmitting,
        } = this.state;

        return (
            <>
                <h1 className="text-lg font-bold leading-tight my-4 mb-10 text-gray-dark">
                    Import Contacts
                </h1>
                <Search value={query} onChange={this.handleSearch} />
                <List
                    activeCheck={email => this.activeCheck(email)}
                    isShowingResults={isShowingResults}
                    searchResults={importOptionsList}
                    selectedContacts={selectedContacts}
                    isShowingActive={isShowingActive}
                    onShowSelected={this.onShowSelected}
                    onClickContact={contact => this.onClickContact(contact)}
                />
                <div className={cx({ 'mt-8': !isShowingActive })}>
                    {selectedContacts.length > 0 && (
                        <Button
                            type="button"
                            color="green"
                            size="md"
                            className={cx('text-14 font-medium block w-full', {
                                'pointer-events-none opacity-60': isSubmitting,
                            })}
                            onClick={this.handleSubmit}
                        >
                            {isSubmitting ? (
                                <Icon icon="spinner" size="lg" className="leading-none" spin />
                            ) : (
                                'Share my campaign'
                            )}
                        </Button>
                    )}
                    <Button
                        type="button"
                        size="md"
                        className="text-14 font-medium block w-full link-gray"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </>
        );
    }
}

export default GoogleImport;
