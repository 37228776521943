import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';

import Divider from 'common/divider';
import Markdown from 'common/markdown';
import Link from 'common/link';

const CampaignStorySponsor = ({ logo, sponsor, theme }) => (
    <div className="my-20 text-center">
        <Divider color={theme.colors.border}>
            <span
                className="uppercase text-2xs font-bold tracking-wide"
                css={{ color: theme.colors.textMuted }}
            >
                Proudly supported by
            </span>
        </Divider>
        {logo &&
            (sponsor.url ? (
                <Link
                    to={sponsor.url}
                    external
                    css={{
                        color: theme.colors.link,
                        '&:hover': { color: theme.colors.linkHover },
                    }}
                >
                    <Image
                        publicId={logo}
                        transformation="logo"
                        alt={sponsor.name}
                        className="block mx-auto max-w-full max-h-24 my-8"
                    />
                </Link>
            ) : (
                <Image
                    publicId={logo}
                    transformation="logo"
                    alt={sponsor.name}
                    className="block mx-auto max-w-full max-h-24 my-8"
                />
            ))}
        <div className="text-sm leading-loose" css={{ color: theme.colors.text }}>
            {sponsor.description && <Markdown>{sponsor.description}</Markdown>}
            {sponsor.url && (
                <p className="mt-4 font-medium">
                    <Link
                        to={sponsor.url}
                        external
                        css={{
                            color: theme.colors.link,
                            '&:hover': { color: theme.colors.linkHover },
                        }}
                    >
                        View Website
                    </Link>
                </p>
            )}
        </div>
    </div>
);

CampaignStorySponsor.propTypes = {
    logo: PropTypes.string.isRequired,
    sponsor: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
    }).isRequired,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            border: PropTypes.string,
            textMuted: PropTypes.string,
            link: PropTypes.string,
            linkHover: PropTypes.string,
            text: PropTypes.string,
        }),
    }).isRequired,
};

export default CampaignStorySponsor;
