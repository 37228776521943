import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import root from 'window-or-global';

const CAN_RENDER = !!root.document;
if (CAN_RENDER && !root.document.getElementById('portal')) {
    const portalRoot = root.document.createElement('div');
    portalRoot.setAttribute('id', 'portal');
    portalRoot.setAttribute('data-testid', 'portal');
    root.document.body.appendChild(portalRoot);
}

export default class Portal extends React.Component {
    constructor(props) {
        super(props);

        if (!CAN_RENDER) return;

        this.el = document.createElement('div');
        this.container = document.getElementById('portal');
    }

    componentDidMount = () => {
        if (CAN_RENDER) this.container.appendChild(this.el);
    };

    componentWillUnmount = () => {
        if (CAN_RENDER) this.container.removeChild(this.el);
    };

    render() {
        const { children } = this.props;
        return CAN_RENDER ? createPortal(children, this.el) : null;
    }
}

Portal.propTypes = {
    children: PropTypes.node.isRequired,
};
