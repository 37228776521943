import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { adopt } from 'react-adopt';

import config from 'config';
import { formatCurrency } from 'utils';

import GlobalContext from 'site/context';
import CampaignPageContext from '../context';

import CampaignFundraiser from './fundraiser-actions';

const GET_FUNDRAISER_LIST = gql`
    query FundraisersByCampaign($campaignId: ID!) {
        fundraiserList: fundraisersByCampaign(
            campaignId: $campaignId
            sort: "-stats.amountRaisedEstimate"
            limit: 0
        ) {
            fundraisers: items {
                id
            }
        }
    }
`;

const GET_FUNDRAISER = gql`
    query FundraiserByUsername($username: String!, $campaignId: ID!) {
        fundraiser: fundraiserByUsername(username: $username, campaignId: $campaignId) {
            id
            user {
                id
                firstName
                lastName
                avatar {
                    id
                }
            }
            message
            stats {
                committedUsers
                amountRaisedEstimate
            }
            referrals {
                activity {
                    type
                }
            }
        }
    }
`;

const UPDATE_FUNDRAISER = gql`
    mutation UpdateFundraiser($id: ID!, $patch: UpdateFundraiserPatchInput!) {
        updateFundraiser(id: $id, patch: $patch)
    }
`;

/* eslint-disable react/destructuring-assignment, react/prop-types */
const CampaignFundraiserWrapper = adopt(
    {
        fundraiserQuery: ({ username, id, render }) => (
            <Query query={GET_FUNDRAISER} variables={{ username, campaignId: id }}>
                {render}
            </Query>
        ),
        fundraiserListQuery: ({ id, render }) => (
            <Query query={GET_FUNDRAISER_LIST} variables={{ campaignId: id }}>
                {render}
            </Query>
        ),
        updateFundraiser: ({ render }) => (
            <Mutation mutation={UPDATE_FUNDRAISER}>{render}</Mutation>
        ),
    },
    ({ fundraiserQuery, fundraiserListQuery, updateFundraiser }) => ({
        error: fundraiserQuery.error || fundraiserListQuery.error,
        loading: fundraiserQuery.loading || fundraiserListQuery.loading,
        fundraiser: get(fundraiserQuery, 'data.fundraiser'),
        fundraisers: get(fundraiserListQuery, 'data.fundraiserList.fundraisers'),
        refetchFundraiser: get(fundraiserQuery, 'refetch'),
        updateFundraiser,
    })
);
/* eslint-enable */

const FundraiserActionsContainer = ({ fundraiser: fundraiserUsername }) => {
    const { showFlash } = useContext(GlobalContext);
    const { id, slug, theme } = useContext(CampaignPageContext);

    return (
        <CampaignFundraiserWrapper username={fundraiserUsername} id={id}>
            {({ error, loading, fundraiser, fundraisers, refetchFundraiser, updateFundraiser }) => {
                if (error) throw error;
                if (loading) return null;
                if (!fundraiser) return null;

                const amountRaised = get(fundraiser, 'stats.amountRaisedEstimate', 0);
                const referralCount = get(fundraiser, 'stats.committedUsers', 0);
                const formattedAmountRaised = formatCurrency(amountRaised);
                const rank = findIndex(fundraisers, x => x.id === fundraiser.id) + 1;
                const isRanked = !!amountRaised;
                const fundraiserCount = fundraisers.length;
                const url = `/${slug}/@${fundraiserUsername}`;
                const fullUrl = `${config('/hostname')}${url}`;

                return (
                    <CampaignFundraiser
                        formattedAmountRaised={formattedAmountRaised}
                        fullUrl={fullUrl}
                        fundraiser={fundraiser}
                        fundraiserCount={fundraiserCount}
                        isRanked={isRanked}
                        rank={rank}
                        theme={theme}
                        url={url}
                        updateFundraiser={async values => {
                            await updateFundraiser({
                                variables: {
                                    id: fundraiser.id,
                                    patch: values,
                                },
                            });

                            return refetchFundraiser();
                        }}
                        referralCount={referralCount}
                        showFlash={showFlash}
                    />
                );
            }}
        </CampaignFundraiserWrapper>
    );
};

FundraiserActionsContainer.propTypes = {
    fundraiser: PropTypes.string.isRequired,
};

export default FundraiserActionsContainer;
