import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { cloudinaryUrl } from 'utils';
import CampaignPageContext from '../../context';

const CampaignHeroImage = ({ className, publicId }) => {
    const { theme } = useContext(CampaignPageContext);

    if (!publicId) return null;

    const image = cloudinaryUrl(publicId, {
        transformation: 'hero-bg',
        fetchFormat: 'auto',
        quality: 'auto',
        secure: true,
    });

    return (
        <div
            className={cx(className, 'bg-top bg-no-repeat bg-cover lg:bg-fixed')}
            css={{
                backgroundImage: `url('${image}')`,
                ...theme.overrides.heroImage,
            }}
        />
    );
};

CampaignHeroImage.propTypes = {
    className: PropTypes.string,
    publicId: PropTypes.string,
};

CampaignHeroImage.defaultProps = {
    className: '',
    publicId: null,
};

export default CampaignHeroImage;
