import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import CampaignPageContext from '../context';

const CampaignPageWrapper = ({ className, children }) => {
    const { theme } = useContext(CampaignPageContext);
    return (
        <div
            className={className}
            css={{
                background: theme.colors.background,
                ...theme.overrides.root,
            }}
        >
            {children}
        </div>
    );
};

CampaignPageWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

CampaignPageWrapper.defaultProps = {
    className: '',
};

export default CampaignPageWrapper;
