import config from 'config';

export default {
    colors: {
        background: config('/tailwind/colors/grayLightest'),
        text: config('/tailwind/colors/grayDark'),
        textMuted: config('/tailwind/colors/grayLight'),
        link: config('/tailwind/colors/green'),
        linkHover: config('/tailwind/colors/greenDark'),
        border: config('/tailwind/colors/grayLighter'),
        titleText: config('/tailwind/colors/white'),
        titleBackground: config('/tailwind/colors/green'),
        buttonText: config('/tailwind/colors/white'),
        buttonBackground: config('/tailwind/colors/green'),
        buttonHoverText: config('/tailwind/colors/white'),
        buttonHoverBackground: config('/tailwind/colors/greenDark'),
        heroBackground: `linear-gradient(
            to bottom,
            transparent 0%,
            transparent 20%,
            ${config('/tailwind/colors/black60')} 100%
        );`,
        panelBackground: config('/tailwind/colors/white'),
        panelSectionBackground: config('/tailwind/colors/grayLightest'),
        panelSectionBorder: config('/tailwind/colors/grayLighter'),
        panelText: config('/tailwind/colors/grayDark'),
        panelTextMuted: config('/tailwind/colors/grayLight'),
        progressEarned: config('/tailwind/colors/green'),
        progressEstimated: config('/tailwind/colors/greenLight'),
        progressRemaining: config('/tailwind/colors/grayLighter'),
    },
    facebookCommentTheme: 'light',
    sponsorLogo: 'dark',
    overrides: {
        root: {},
        hero: {},
        heroImage: {},
        heroOverlay: {},
        title: {},
        proceedStatement: {},
        pledgeButton: {},
        stats: {},
        amountRaised: {},
        headline: {},
        content: {},
        recipient: {},
        recipientLeadIn: {},
        recipientName: {},
        recipientDescription: {},
        recipientTaxStatement: {},
        comments: {},
        share: {},
        contact: {},
    },
};
