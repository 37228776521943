import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import cx from 'classnames';

import GlobalContext from 'site/context';
import Icon from 'common/icon';
import CampaignPageContext from '../context';

const CampaignShare = ({ message, url }) => {
    const { showFlash } = useContext(GlobalContext);
    const { theme, isDraft } = useContext(CampaignPageContext);

    return (
        <div className={cx('mt-16 text-center', { 'opacity-25 pointer-events-none': isDraft })}>
            <h3
                className="text-2xs font-bold uppercase tracking-wide"
                css={{
                    ...theme.overrides.share,
                    color: theme.colors.text,
                }}
            >
                Share this campaign
            </h3>
            <div className="mt-4">
                <FacebookShareButton
                    url={url}
                    className="transition inline-flex w-14 h-14 bg-facebook text-white hover:text-black-40 justify-center items-center mx-1"
                >
                    <Icon icon="facebook-f" type="brand" size="lg" />
                </FacebookShareButton>
                <TwitterShareButton
                    url={url}
                    title={message}
                    className="transition inline-flex w-14 h-14 bg-twitter text-white hover:text-black-40 justify-center items-center mx-1"
                >
                    <Icon icon="twitter" type="brand" size="lg" />
                </TwitterShareButton>

                <CopyToClipboard
                    text={url}
                    onCopy={() =>
                        showFlash({
                            type: 'info',
                            iconName: 'paperclip',
                            iconStyle: 'regular',
                            title: 'Copied!',
                            message: 'Campaign url has been copied to your clipboard.',
                            duration: 3000,
                        })
                    }
                >
                    <button
                        type="button"
                        className="transition inline-flex w-14 h-14 bg-gray-dark text-white hover:text-black-40 justify-center items-center mx-1"
                    >
                        <Icon icon="paperclip" size="lg" />
                    </button>
                </CopyToClipboard>
            </div>
        </div>
    );
};

CampaignShare.propTypes = {
    message: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default CampaignShare;
