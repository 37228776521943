import React from 'react';
import PropTypes from 'prop-types';

import config from 'config';
import SEO from 'common/seo';

const CampaignSEO = ({ campaign, description, socialContent, socialContentSecure }) => (
    <SEO>
        <title>{campaign.name}</title>
        <meta property="og:title" content={`Support ${campaign.name} on ${config('/sitename')}`} />
        <meta property="og:description" content={description} />
        {socialContent && <meta property="og:image" content={socialContent} />}
        {socialContentSecure && (
            <meta property="og:image:secure_url" content={socialContentSecure} />
        )}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={campaign.name} />
        <meta name="twitter:description" content={description} />
        {socialContent && <meta name="twitter:image" content={socialContent} />}
    </SEO>
);

CampaignSEO.propTypes = {
    campaign: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    description: PropTypes.string.isRequired,
    socialContent: PropTypes.string.isRequired,
    socialContentSecure: PropTypes.string.isRequired,
};

export default CampaignSEO;
