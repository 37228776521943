import React, { createContext, useMemo } from 'react';
import isPlainObject from 'lodash/isPlainObject';
import PropTypes from 'prop-types';
import themes from './themes';

const CampaignPageContext = createContext();

const compileTheme = (theme, overrides) => {
    const baseTheme = theme ? themes[theme] : themes.dark;
    let customTheme = {};

    if (overrides) {
        try {
            customTheme = JSON.parse(overrides);
        } catch (err) {
            console.warn('Error parsing theme overrides', err);
        }

        if (!isPlainObject(customTheme)) {
            console.warn('Parsed theme overrides is not an object', overrides);
            customTheme = {};
        }
    }

    return {
        ...baseTheme,
        colors: {
            ...baseTheme.colors,
            ...customTheme.colors,
        },
        overrides: {
            ...baseTheme.overrides,
            ...customTheme.overrides,
        },
    };
};

export const CampaignPageProvider = ({
    children,
    id,
    slug,
    status,
    theme,
    themeOverrides,
    fundraiser,
    defaultMessage,
    isAnimationComplete,
    isFundraisingOnLoad,
}) => {
    // We wrap the state object that we're going to expose in a
    // useMemo call to prevent unnecessary re-renders
    const value = useMemo(
        () => ({
            id,
            slug,
            isDraft: status === 'draft',
            theme: compileTheme(theme, themeOverrides),
            fundraiser,
            defaultMessage,
            isAnimationComplete,
            isFundraisingOnLoad,
        }),
        [id, slug, isAnimationComplete, isFundraisingOnLoad]
    );

    return <CampaignPageContext.Provider value={value}>{children}</CampaignPageContext.Provider>;
};

CampaignPageProvider.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['draft', 'published']).isRequired,
    isAnimationComplete: PropTypes.bool.isRequired,
    isFundraisingOnLoad: PropTypes.bool.isRequired,
    fundraiser: PropTypes.string,
    slug: PropTypes.string,
    theme: PropTypes.oneOf(['light', 'dark']),
    themeOverrides: PropTypes.string,
};

CampaignPageProvider.defaultProps = {
    fundraiser: '',
    theme: 'dark',
    themeOverrides: null,
    slug: null,
};

export default CampaignPageContext;
export const CampaignPageConsumer = CampaignPageContext.Consumer;
