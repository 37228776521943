import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import SkewText from 'common/skew-text';
import ScheduleRow from './row';
import ContentMask from '../content-mask';

const CampaignSchedule = ({ campaign, performances, theme }) => (
    <div className="my-20">
        <SkewText
            component="h2"
            backgroundColor={theme.colors.titleBackground}
            textColor={theme.colors.titleText}
            className="font-display uppercase font-regular leading-tight text-center mt-20"
            css={{ fontSize: '5rem' }}
        >
            Schedule
        </SkewText>
        <ContentMask closedText="View Full Schedule" dividerClassName="h-12" height={215}>
            <table className="w-full mt-12">
                <thead>
                    <tr className="capitalize text-left text-xs font-medium">
                        <th
                            scope="col"
                            className="pb-2 w-1/5"
                            css={{ borderColor: theme.colors.border }}
                        >
                            Date
                        </th>
                        <th scope="col" className="pb-2" css={{ borderColor: theme.colors.border }}>
                            Name
                        </th>
                        <th
                            scope="col"
                            className="pb-2 text-center truncate w-1/5"
                            css={{ borderColor: theme.colors.border }}
                        >
                            {get(campaign, 'metricName.plural', 'Results')}
                        </th>
                        <th
                            scope="col"
                            className="pb-2 text-right w-1/5"
                            css={{ borderColor: theme.colors.border }}
                        >
                            Earned
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {performances.map(performance => (
                        <ScheduleRow
                            key={performance.id}
                            date={performance.event.startDate}
                            name={performance.event.name}
                            result={performance.isFinal ? performance.result : null}
                            amountRaised={
                                performance.isFinal
                                    ? performance.result *
                                      (get(campaign, 'stats.pledgeValue', 0) / 100)
                                    : null
                            }
                        />
                    ))}
                </tbody>
            </table>
        </ContentMask>
    </div>
);

CampaignSchedule.propTypes = {
    campaign: PropTypes.shape({}).isRequired,
    performances: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            titleBackground: PropTypes.string,
            titleText: PropTypes.string,
            border: PropTypes.string,
        }),
    }).isRequired,
};

export default CampaignSchedule;
