import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import config from 'config';
import Markdown from 'common/markdown';

const campaignContent = css`
    line-height: ${config('/tailwind/leading/loose')};

    h2 {
        font-size: ${config('/tailwind/fontSizes/lg')};
        font-weight: ${config('/tailwind/fontWeights/regular')};
    }

    p,
    ul,
    img {
        margin: 1.25em 0;
    }

    hr {
        height: 2px;
        display: block;
        margin: 2.5em 0;
    }

    img {
        display: block;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
`;

const CampaignContent = ({ campaign, theme }) => (
    <Markdown
        css={[
            campaignContent,
            {
                color: theme.colors.text,
                a: {
                    color: theme.colors.link,
                    '&:hover': { color: theme.colors.linkHover },
                },
                hr: { backgroundColor: theme.colors.border },
                ...theme.overrides.content,
            },
        ]}
    >
        {campaign.fullDescription}
    </Markdown>
);

CampaignContent.propTypes = {
    campaign: PropTypes.shape({
        fullDescription: PropTypes.string,
    }).isRequired,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            text: PropTypes.string,
            link: PropTypes.string,
            linkHover: PropTypes.string,
            border: PropTypes.string,
        }),
        overrides: PropTypes.object,
    }).isRequired,
};

export default CampaignContent;
