import React from 'react';
import PropTypes from 'prop-types';

import Link from 'common/link';
import Icon from 'common/icon';

const ManagerActions = ({ action }) => (
    <div className="absolute z-30 pin-t pin-x p-4 md:p-8 text-center bg-black-80">
        <div className="text-sm md:text-base md:flex justify-center items-center -ml-4 -mt-2">
            <div className="pl-4 pt-2">
                <p className="font-medium text-white">You are a manager of this campaign.</p>
            </div>
            <div className="pl-4 pt-2">
                <p>
                    <Link to={action.href} className="link-green" legacy>
                        {action.label}
                        <Icon icon="chevron-double-right" size="xs" className="ml-1" />
                    </Link>
                </p>
            </div>
        </div>
    </div>
);

ManagerActions.propTypes = {
    action: PropTypes.shape({
        label: PropTypes.string,
        href: PropTypes.string,
    }).isRequired,
};

export default ManagerActions;
