import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';

import Icon from 'common/icon';
import Button from 'common/button';

class CampaignContactForm extends Component {
    static propTypes = {
        contactCampaign: PropTypes.func.isRequired,
        formType: PropTypes.string.isRequired,
        flashMessage: PropTypes.func.isRequired,
        authEmail: PropTypes.string,
        onAfterSubmit: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        reportCampaign: PropTypes.func.isRequired,
    };

    static defaultProps = {
        authEmail: '',
    };

    get initialValues() {
        const { authEmail } = this.props;
        return {
            email: authEmail,
            message: '',
        };
    }

    get validate() {
        const { formType } = this.props;

        const messageValidator = formType === 'Report' ? yup.string() : yup.string().required();

        return yup.object().shape({
            email: yup
                .string()
                .email()
                .required(),
            message: messageValidator,
        });
    }

    handleSubmit = async (values, { setSubmitting }) => {
        const {
            contactCampaign,
            flashMessage,
            formType,
            onAfterSubmit,
            reportCampaign,
        } = this.props;

        try {
            const isReporting = formType === 'Report';
            const onSubmit = isReporting ? reportCampaign : contactCampaign;

            await onSubmit({ ...values });

            setSubmitting(false);

            flashMessage({
                type: 'info',
                title: 'Message Sent!',
                duration: 3000,
                message: isReporting
                    ? 'You have reported this campaign.'
                    : 'Your message has been sent to the campaign.',
            });

            onAfterSubmit();
        } catch (err) {
            setSubmitting(false);
            flashMessage({
                type: 'danger',
                title: 'Woops!',
                message: 'Something went wrong when trying to submit this form. Please try again',
                duration: 5000,
            });

            onAfterSubmit();
        }
    };

    render() {
        const { authEmail, formType, onCancel } = this.props;

        return (
            <Formik
                initialValues={this.initialValues}
                validationSchema={this.validate}
                onSubmit={this.handleSubmit}
            >
                {({ isSubmitting, touched, errors }) => (
                    <Form>
                        <h1 className="m-8 font-light text-center uppercase text-lg">
                            {formType} Campaign
                        </h1>
                        <div className="mt-4 px-8">
                            {!authEmail && (
                                <div className="p-2 border border-gray-lighter">
                                    <div className="flex text-left bg-white relative">
                                        <label
                                            htmlFor="email"
                                            className={cx(
                                                'font-medium w-1/4 text-xs ml-2 self-center',
                                                {
                                                    'text-red': touched.errors && errors.email,
                                                }
                                            )}
                                        >
                                            Email
                                        </label>
                                        <Field
                                            type="text"
                                            name="email"
                                            id="email"
                                            data-testid="email"
                                            placeholder="john.smith@example.com"
                                            className={cx(
                                                'input-default w-3/4 bg-white border-0 h-4 pr-0',
                                                {
                                                    'bg-red-lightest':
                                                        touched.errors && errors.email,
                                                }
                                            )}
                                        />
                                    </div>
                                </div>
                            )}
                            <div
                                className={cx('p-2 border border-gray-lighter', {
                                    'border-t-0': !authEmail,
                                })}
                            >
                                <label
                                    htmlFor="message"
                                    className={cx('font-medium w-1/4 text-xs ml-2 self-center', {
                                        'text-red': touched.errors && errors.message,
                                    })}
                                >
                                    Message
                                </label>
                                <Field
                                    component="textarea"
                                    name="message"
                                    id="message"
                                    data-testid="message"
                                    className={cx(
                                        'inherit-all text-left w-full h-32 py-3 px-6 my-2 rounded border-none',
                                        {
                                            'opacity-60 pointer-events-none': isSubmitting,
                                        }
                                    )}
                                    autoFocus
                                    maxLength="500"
                                />
                            </div>
                            <div className="my-8">
                                <Button
                                    type="submit"
                                    color="green"
                                    size="md"
                                    data-testid="submit"
                                    className={cx('block w-full text-sm font-medium', {
                                        'pointer-events-none opacity-60': isSubmitting,
                                    })}
                                >
                                    {isSubmitting ? (
                                        <Icon
                                            icon="spinner"
                                            size="lg"
                                            className="leading-none"
                                            spin
                                        />
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                                <Button
                                    size="md"
                                    type="button"
                                    className="block w-full text-sm font-medium link-gray mt-2"
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default CampaignContactForm;
