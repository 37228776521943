import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import removeMd from 'remove-markdown';

import { cloudinaryUrl } from 'utils';

import CampaignSEO from './campaign-seo';

import CampaignPageContext from '../context';

const GET_SEO = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            name
            description
            heroImage {
                id
            }
        }
    }
`;

const CampaignSEOContainer = () => {
    const { id } = useContext(CampaignPageContext);

    return (
        <Query query={GET_SEO} variables={{ id }}>
            {({ error, loading, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { campaign } = data;

                let description = removeMd(campaign.description || '');
                if (description.length > 250) description = `${description.substr(0, 250)}…`;

                return (
                    <CampaignSEO
                        campaign={campaign}
                        description={description}
                        socialContent={
                            campaign.heroImage
                                ? cloudinaryUrl(campaign.heroImage.id, {
                                      transformation: 'facebook',
                                      secure: false,
                                  })
                                : ''
                        }
                        socialContentSecure={
                            campaign.heroImage
                                ? cloudinaryUrl(campaign.heroImage.id, {
                                      transformation: 'facebook',
                                      secure: true,
                                  })
                                : ''
                        }
                    />
                );
            }}
        </Query>
    );
};

export default CampaignSEOContainer;
