import React from 'react';
import PropTypes from 'prop-types';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import Modal from 'common/modal';
import Button from 'common/button';
import Icon from 'common/icon';
import { cloudinaryUrl, formatCurrency } from 'utils';

const ContributeThanks = ({ type, amount, url, shareText, isOpen, onClose }) => (
    <Modal close={onClose} isOpen={isOpen}>
        <div
            className="p-14 text-center"
            css={{ backgroundImage: `url('${cloudinaryUrl('pattern/celebrate')}')` }}
        >
            <h1 className="text-gray-dark text-24 font-bold leading-tight">
                Thanks for your {formatCurrency(amount)} {type}!
            </h1>
            <p className="text-16 mt-4">
                Give this campaign a boost by sharing the campaign. Sharing can increase pledges by
                3x!
            </p>

            <div className="mt-8">
                <FacebookShareButton
                    className="button button--facebook button--md text-14 font-medium w-full flex justify-center items-center"
                    url={url}
                >
                    <Icon icon="facebook-f" type="brand" className="text-21 mr-4" />
                    Share on Facebook
                </FacebookShareButton>
                <TwitterShareButton
                    className="button button--md button--twitter mt-2 text-14 font-medium w-full flex justify-center items-center"
                    url={url}
                    title={shareText}
                >
                    <Icon icon="twitter" type="brand" className="text-21 mr-4" />
                    Share on Twitter
                </TwitterShareButton>
            </div>

            <Button className="mt-8 text-14 font-medium link-gray" onClick={onClose}>
                Close this
            </Button>
        </div>
    </Modal>
);

ContributeThanks.propTypes = {
    type: PropTypes.oneOf(['pledge', 'donation']).isRequired,
    amount: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    shareText: PropTypes.string.isRequired,
};

export default ContributeThanks;
