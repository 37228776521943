import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';

import CampaignPageContext from '../../context';
import CampaignHeroProceeds from './campaign-hero-proceeds';

const GET_CAMPAIGN = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            recipient {
                id
                name
                url
                type
                isVerified
            }
            owner {
                id
                firstName
                lastName
                avatar {
                    id
                }
            }
            benefits
            shouldDisplayBenefitInHero
        }
    }
`;

export default props => {
    const { id, theme } = useContext(CampaignPageContext);

    return (
        <Query query={GET_CAMPAIGN} variables={{ id }}>
            {({ error, loading, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { campaign } = data;
                const useBenefit = campaign.shouldDisplayBenefitInHero;

                // Owner information is displayed when the recipient has not been verified
                const showOwner = campaign.recipient.isVerified;

                // We don't want to display information for individual-type recipients
                const recipient =
                    campaign.recipient.type === 'individual' ? {} : campaign.recipient;

                return (
                    <CampaignHeroProceeds
                        name={useBenefit ? campaign.benefits : recipient.name}
                        url={useBenefit ? null : recipient.url}
                        owner={showOwner ? null : campaign.owner}
                        theme={theme}
                        {...props}
                    />
                );
            }}
        </Query>
    );
};
