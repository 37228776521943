import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import { withRouter } from 'react-router-dom';
import { Comments } from 'react-facebook';
import cx from 'classnames';

import CampaignPageContext from '../context';

const CampaignComments = ({ location }) => {
    const { isDraft, theme } = useContext(CampaignPageContext);

    return (
        <div
            className={cx('mt-20 pt-10 border-t', {
                'opacity-25 pointer-events-none': isDraft,
            })}
            css={{
                borderColor: theme.colors.border,
                ...theme.overrides.comments,
                iframe: { width: '100% !important' },
            }}
        >
            <Comments
                href={`${config('/hostname')}${location.pathname}`}
                colorScheme={theme.facebookCommentTheme}
                width="100%"
            />
        </div>
    );
};

CampaignComments.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

CampaignComments.defaultProps = {
    location: {
        pathname: '',
    },
};

export default withRouter(CampaignComments);
