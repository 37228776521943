import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Image } from 'cloudinary-react';
import { Formik, Form, Field } from 'formik';
import { scroller } from 'react-scroll';
import * as yup from 'yup';

import Button from 'common/button';
import ModalContext from 'common/modal/context';

const FundraiserEditMessage = ({ message, onSubmit }) => {
    const { id } = useContext(ModalContext);

    return (
        <>
            <h1 className="text-lg font-bold leading-tight my-4 text-gray-dark">
                Edit Personal Message
            </h1>
            <div className="w-64 h-64 block my-4 mx-auto">
                <Image publicId="fundraise-step1" />
            </div>
            <Formik
                initialValues={{ message }}
                validationSchema={yup
                    .object()
                    .shape({ message: yup.string().required('Message is required.') })}
                onSubmit={onSubmit}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <div>
                            <Field
                                component="textarea"
                                name="message"
                                className={cx(
                                    'input-default text-14 h-48 leading-normal px-6 py-3 w-full',
                                    {
                                        'opacity-60 pointer-events-none': isSubmitting,
                                        'is-invalid': touched.message && errors.message,
                                    }
                                )}
                                maxLength="500"
                                onSelect={() => {
                                    scroller.scrollTo('message', {
                                        duration: 500,
                                        smooth: true,
                                        offset: -100,
                                        containerId: id,
                                    });
                                }}
                            />
                            <div className="text-12 text-center mt-2">
                                {errors.message && touched.message ? (
                                    <p className="text-red-50">{errors.message}</p>
                                ) : (
                                    <p className="text-gray-40">Maximum 500 characters</p>
                                )}
                            </div>
                        </div>
                        <div className="mt-8">
                            <Button
                                type="submit"
                                color="green"
                                size="md"
                                className="text-14 font-medium block w-full"
                            >
                                Save and continue
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

FundraiserEditMessage.propTypes = {
    message: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default FundraiserEditMessage;
