import React, { useContext } from 'react';

import CampaignPageContext from '../../context';

const CampaignHeroOverlay = () => {
    const { theme } = useContext(CampaignPageContext);

    return (
        <div
            css={{
                background: theme.colors.heroBackground,
                ...theme.overrides.heroOverlay,
            }}
        />
    );
};

export default CampaignHeroOverlay;
