import React, { useContext, useState } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import get from 'lodash/get';

import CampaignPageContext from '../context';

import CampaignStats from './campaign-stats';

const CAMPAIGN_STATS = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            goal
            completionAt
            isDonationOnly
            isComplete
            metricName {
                singular
                plural
            }
            stats {
                amountRaisedEstimate
                amountRaisedEstimatePast
                amountRaisedEstimateFuture
                amountRaisedActual
                pledgeValue
                pledgeValuePast
                performanceEstimatedTotal
                performanceActualTotal
                performanceRemaining
                contributorCount
            }
            sponsor {
                displayInStats
            }
        }
    }
`;

const CampaignStatsContainer = () => {
    const [isShowingDetails, onToggleDetails] = useState(false);

    const { id, theme } = useContext(CampaignPageContext);

    return (
        <Query query={CAMPAIGN_STATS} variables={{ id }}>
            {({ loading, error, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { campaign } = data;

                const shouldDisplaySponsor = get(campaign, 'sponsor.displayInStats', false);

                const amountRaised = Math.round(
                    campaign.isComplete
                        ? get(campaign, 'stats.amountRaisedActual', 0)
                        : get(campaign, 'stats.amountRaisedEstimate', 0)
                );

                const amountEarned = Math.round(
                    campaign.isComplete
                        ? amountRaised
                        : get(campaign, 'stats.amountRaisedEstimatePast', 0)
                );

                return (
                    <CampaignStats
                        amountRaised={amountRaised}
                        amountEarned={amountEarned}
                        campaign={campaign}
                        isShowingDetails={isShowingDetails}
                        shouldDisplaySponsor={shouldDisplaySponsor}
                        theme={theme}
                        toggleDetails={() => onToggleDetails(prev => !prev)}
                    />
                );
            }}
        </Query>
    );
};

export default CampaignStatsContainer;
