import React, { useContext } from 'react';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import get from 'lodash/get';

import CampaignStorySponsor from './campaign-story-sponsor';

import CampaignPageContext from '../../context';

const GET_SPONSOR = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            sponsor {
                name
                description
                url
                logoLight {
                    id
                }
                logoDark {
                    id
                }
            }
        }
    }
`;

const CampaignStorySponsorContainer = () => {
    const { id, theme } = useContext(CampaignPageContext);

    return (
        <Query query={GET_SPONSOR} variables={{ id }}>
            {({ error, loading, data }) => {
                if (error) throw error;
                if (loading) return null;

                const { sponsor } = data.campaign;
                const logo =
                    theme.sponorLogo === 'dark'
                        ? get(sponsor, 'logoDark.id')
                        : get(sponsor, 'logoLight.id');

                return <CampaignStorySponsor logo={logo} sponsor={sponsor} theme={theme} />;
            }}
        </Query>
    );
};

export default CampaignStorySponsorContainer;
