import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'common/avatar';

const CampaignFundraiser = ({
    formattedAmountRaised,
    fundraiser,
    fundraiserCount,
    isRanked,
    rank,
    referralCount,
    supporterTerm,
    theme,
}) => (
    <div className="lg:-mt-48">
        <div
            className="p-12 rounded shadow-lg mb-12 text-base leading-loose"
            css={{
                background: theme.colors.panelBackground,
                color: theme.colors.panelText,
            }}
        >
            <div className="md:flex -ml-8 -mt-8">
                <div className="pl-8 pt-8 flex-no-shrink">
                    <Avatar
                        firstName={fundraiser.user.firstName}
                        lastName={fundraiser.user.lastName}
                        imageId={fundraiser.user.avatar.id}
                        className="w-24 rounded-full block"
                    />
                </div>
                <div className="pl-8 pt-8">
                    <div className="mb-4">
                        <p className="font-bold">
                            A message from {fundraiser.user.firstName} {fundraiser.user.lastName}:
                        </p>
                        {isRanked && (
                            <p
                                className="font-medium"
                                css={{
                                    color: theme.colors.panelTextMuted,
                                }}
                            >
                                Ranked {rank} of {fundraiserCount} fundraisers -{' '}
                                {formattedAmountRaised} raised by {referralCount} {supporterTerm}
                            </p>
                        )}
                    </div>
                    <p>{fundraiser.message}</p>
                </div>
            </div>
        </div>
    </div>
);

CampaignFundraiser.propTypes = {
    formattedAmountRaised: PropTypes.string.isRequired,
    fundraiser: PropTypes.shape({
        message: PropTypes.string,
        user: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            avatar: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
    }).isRequired,
    fundraiserCount: PropTypes.number.isRequired,
    isRanked: PropTypes.bool.isRequired,
    rank: PropTypes.number.isRequired,
    referralCount: PropTypes.number.isRequired,
    supporterTerm: PropTypes.string.isRequired,
    theme: PropTypes.shape({
        colors: PropTypes.shape({
            panelBackground: PropTypes.string,
            panelText: PropTypes.string,
            panelTextMuted: PropTypes.string,
        }),
    }).isRequired,
};

export default CampaignFundraiser;
