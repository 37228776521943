import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { css, keyframes } from '@emotion/core';
import cx from 'classnames';
import noop from 'lodash/noop';

import FundraiseContext from '../context';

const fade = keyframes`
    0% { opacity: .25; }
    100% { opacity: 1; }
`;

const activeNode = css`
    animation: ${fade} 0.75s linear 3 alternate;
`;

const positions = {
    intro: 1,
    message: 2,
    signIn: 3,
    register: 3,
    userDetails: 3,
    confirm: 3,
    refer: 4,
    googleRefer: 4,
    manualRefer: 4,
    thanks: 5,
};

const nodes = ['intro', 'message', 'confirm', 'refer', 'thanks'];

const ProgressBar = ({ onChange }) => {
    const { activePage } = useContext(FundraiseContext);
    const activePosition = positions[activePage];

    return (
        <div className="flex items-center mb-8 transition">
            {nodes.map(node => {
                const nodePosition = positions[node];
                const isFuture = nodePosition > activePosition;
                const isActive = nodePosition === activePosition;
                const canNavigate = nodePosition < activePosition;
                const hasSeparator = nodePosition > 1;

                return (
                    <Fragment key={node}>
                        {hasSeparator && (
                            <span
                                className={cx('inline-block flex-grow h-1 bg-gray-lighter', {
                                    'bg-gray-20': isFuture,
                                    'bg-green': !isFuture,
                                })}
                                css={isActive ? activeNode : undefined}
                            />
                        )}
                        <button
                            type="button"
                            onClick={canNavigate ? () => onChange(node) : noop}
                            className={cx('inline-block w-7 h-7 rounded-full', {
                                'pointer-events-none bg-gray-20': isFuture,
                                'bg-green-50': !isFuture,
                            })}
                            css={isActive ? activeNode : undefined}
                        />
                    </Fragment>
                );
            })}
        </div>
    );
};

ProgressBar.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default ProgressBar;
