import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import numeral from 'numeral';

import Icon from 'common/icon';

import CampaignPageContext from '../../context';

const CampaignPulse = ({ isComplete, endDate, contributorCount }) => {
    const { theme } = useContext(CampaignPageContext);

    const today = DateTime.local();
    const timeRemaining = DateTime.fromISO(endDate)
        .diff(today, 'days')
        .toObject();

    const formattedContributorCount = numeral(contributorCount).format('0,0');
    const formattedDaysRemaining = numeral(timeRemaining.days).format('0,0');

    return (
        <div
            className="rounded border-2 flex text-xs font-medium mt-12"
            css={{
                borderColor: theme.colors.panelSectionBorder,
                color: theme.colors.panelText,
            }}
        >
            <div className="w-1/2 p-4 text-center">
                <Icon
                    icon="calendar-alt"
                    type="regular"
                    className="text-sm mr-3"
                    css={{ color: theme.colors.buttonBackground }}
                />
                {isComplete && 'Ended'}
                {!isComplete &&
                    (timeRemaining.days < 1
                        ? 'Ends today'
                        : `${formattedDaysRemaining} day${
                              timeRemaining.days !== 1 ? 's' : ''
                          } left`)}
            </div>
            <div
                className="w-1/2 p-4 text-center border-l-2"
                css={{ borderColor: theme.colors.panelSectionBorder }}
            >
                <Icon
                    icon="users"
                    type="solid"
                    className="text-sm mr-3"
                    css={{ color: theme.colors.buttonBackground }}
                />
                {formattedContributorCount} pledgers
            </div>
        </div>
    );
};

CampaignPulse.propTypes = {
    isComplete: PropTypes.bool,
    endDate: PropTypes.string,
    contributorCount: PropTypes.number,
};

CampaignPulse.defaultProps = {
    isComplete: false,
    endDate: null,
    contributorCount: 0,
};

export default CampaignPulse;
