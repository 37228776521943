import React from 'react';
import PropTypes from 'prop-types';
import { adopt } from 'react-adopt';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import get from 'lodash/get';

import config from 'config';
import ContributeThanks from './contribute-thanks';

const GET_CAMPAIGN = gql`
    query CampaignById($id: ID!) {
        campaign: campaignById(id: $id) {
            id
            slug
            name
            metricName {
                singular
            }

            defaultPledgedMessage
            defaultDonatedMessage
        }
    }
`;

/* eslint-disable react/prop-types, react/destructuring-assignment */
const Wrapper = adopt(
    {
        campaignQuery: ({ campaign, render }) => (
            <Query query={GET_CAMPAIGN} variables={{ id: campaign }}>
                {render}
            </Query>
        ),
    },
    ({ campaignQuery }) => ({
        error: campaignQuery.error,
        loading: campaignQuery.loading,
        campaign: get(campaignQuery, 'data.campaign'),
    })
);
/* eslint-enable react/prop-types, react/destructuring-assignment */

const ContributeThanksContainer = ({ campaign: campaignId, type, ...props }) => (
    <Wrapper campaign={campaignId}>
        {({ error, loading, campaign }) => {
            if (error) throw error;
            if (loading) return null;

            const message =
                type === 'pledge' ? campaign.defaultPledgedMessage : campaign.defaultDonatedMessage;

            return (
                <ContributeThanks
                    url={`${config('/hostname')}/${campaign.slug}`}
                    shareText={message}
                    type={type}
                    {...props}
                />
            );
        }}
    </Wrapper>
);

ContributeThanksContainer.propTypes = {
    campaign: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['pledge', 'donation']).isRequired,
    amount: PropTypes.number.isRequired,
};

export default ContributeThanksContainer;
