import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';

import { cloudinaryUrl } from 'utils';
import Button from 'common/button';
import Link from 'common/link';

const bgImage = cloudinaryUrl('match-bg.jpg', {
    fetchFormat: 'auto',
    quality: 'auto',
    secure: true,
});

const CampaignMatch = ({ match, slug, sponsor }) => (
    <div
        className="my-20 mx-auto shadow-lg bg-cover bg-white"
        css={{
            maxWidth: 650,
            backgroundImage: `url('${bgImage}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}
    >
        <div className="py-12 px-8 md:px-16">
            <div className="flex items-center flex-col-reverse text-center md:flex-row md:text-left">
                <div>
                    <span className="px-3 py-1 bg-gray-lighter text-gray-light uppercase text-2xs font-bold tracking-wide rounded-sm inline-block">
                        Special Offer
                    </span>
                    <h2 className="mt-4 text-xl font-bold text-gray-darker">
                        {match.messaging.title}
                    </h2>
                    <p className="m-0 mt-4 text-gray-base font-medium leading-normal">
                        {match.messaging.title}
                    </p>
                </div>
                <div className="mb-8 md:mb-0 md:pl-8">
                    <Image
                        publicId={sponsor.logoDark.id}
                        alt={sponsor.name}
                        className="block max-w-full w-48 md:w-64"
                    />
                </div>
            </div>
        </div>
        <Button
            color="blue"
            size="md"
            component={Link}
            to={`/${slug}/contribute`}
            className="w-full rounded-none font-medium"
        >
            Make your pledge today!
        </Button>
    </div>
);

CampaignMatch.propTypes = {
    match: PropTypes.shape({
        messaging: PropTypes.shape({
            title: PropTypes.string,
        }),
    }).isRequired,
    slug: PropTypes.string.isRequired,
    sponsor: PropTypes.shape({
        name: PropTypes.string,
        logoDark: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
};

export default CampaignMatch;
